import SMARTFAQ_TYPES from './SmartFAQTypes';

const {
  FAQ_PAGE,
  FAQ_PUSH,
  FAQ_STANDALONE,
  FAQ_INPUT,
  FAQ_SELECT,
  FAQ_CONTEXT,
} = SMARTFAQ_TYPES || {};

const BASE_URL = 'https://assets.app.smart-tribune.com/ipm';
type SmartFAQConfig = {
  [type: string]: {
    eventListener: string;
    src: {
      prod: string | null;
      preprod: string | null;
    };
    htmlContent: string;
  };
};

const config: SmartFAQConfig = {
  [FAQ_PAGE]: {
    eventListener: 'STFAQLoaded',
    src: {
      preprod: `${BASE_URL}/FAQ/public/faq.main.js`,
      prod: `${BASE_URL}/FAQ/faq.main.js`,
    },
    htmlContent: `<div id="st-faq" style={{ minHeight: '100vh' }} />`,
  },
  [FAQ_PUSH]: {
    eventListener: 'STPUSHLoaded',
    src: {
      preprod: `${BASE_URL}/PUSH/public/push.main.js`,
      prod: `${BASE_URL}/PUSH/push.main.js`,
    },
    htmlContent: '',
  },
  [FAQ_STANDALONE]: {
    eventListener: 'STSearchStandaloneLoaded',
    src: {
      preprod: `${BASE_URL}/SearchStandalone/searchstandalone.main.js`,
      prod: null,
    },
    htmlContent: `<div id="st-search-standalone" />`,
  },
  [FAQ_INPUT]: {
    eventListener: 'STContactFormInputLoaded',
    src: {
      preprod: `${BASE_URL}/ContactFormInput/contactforminput.main.js`,
      prod: null,
    },
    htmlContent: `(
        <>
          <Input
            item={{
              id: 'st-input',
              name: 'st-input',
              placeholder: 'Entrer votre question',
            }}
            className="ap-SmartFAQ-input"
          />
          <div id="st-contact-form-input-container" className="ap-SmartFAQ-result" />
        </>
      )`,
  },
  [FAQ_SELECT]: {
    eventListener: 'STContactFormSelectLoaded',
    src: {
      preprod: `${BASE_URL}/ContactFormSelect/contactformselect.main.js`,
      prod: null,
    },
    htmlContent: `(
        <>
          <Input
            item={{
              id: 'st-contact-form-select-container',
              name: 'st-select',
              placeholder: 'choisissez une catégorie',
              type: 'select',
              options: [],
            }}
            className="ap-SmartFAQ-select"
          />
          <div id="st-select" />
        </>
      )`,
  },
  [FAQ_CONTEXT]: {
    eventListener: 'STFAQContextLoaded',
    src: {
      preprod: `${BASE_URL}/FAQContext/faqcontext.main.js`,
      prod: null,
    },
    htmlContent: `<div id="st-faq-context" />`,
  },
};

export default config;
