import React from 'react';
import { Redirect } from 'react-router-dom';
import { EditionProps } from '.';
import './Edition.css';
import LoadingScreen from '../LoadingScreen';
import browserHistory from '../../utils/browser_history';
import Message from '../Message';
import icon from '../../images/ic_chevron_right@2x.png';
import { getUrlParamsFromLs } from '../../utils/url';
import { HOME_PAGE, PAY_PAGE, USER_LOGIN_PAGE } from '../../utils/routes';

declare global {
  interface Number {
    between: (a: number, b: number) => Boolean;
  }
}
// eslint-disable-next-line no-extend-native,func-names
Number.prototype.between = function (a, b) {
  const min = Math.min.apply(Math, [a, b]);
  const max = Math.max.apply(Math, [a, b]);
  return a >= min && b <= max;
};

class Edition extends React.Component<
  EditionProps,
  { isLoading: boolean; editions: any; error: string; intentError: string }
> {
  constructor(props: EditionProps) {
    super(props);
    this.props.resetEditionState();
    this.state = {
      isLoading: true,
      editions: [],
      error: '',
      intentError: '',
    };
  }

  async componentDidMount() {
    const { offer, duration, currentProduct, productID } =
      this.props.general.appState;
    // const { currentUser } = this.props.user;
    if (
      (offer === null && productID === null) ||
      (duration === null && productID === null)
    ) {
      browserHistory.push(HOME_PAGE);
    }
    try {
      if (currentProduct === null) {
        browserHistory.push(HOME_PAGE);
      }

      if (currentProduct?.editionDto.length === 0) {
        const { gift } = this.props.general.appState;
        browserHistory.push(
          (gift || '').length > 0
            ? USER_LOGIN_PAGE
            : `${PAY_PAGE}${getUrlParamsFromLs()}`
        );
      }

      if (currentProduct?.editionDto.length === 1) {
        this.handleUniqueEdition(currentProduct);
      }

      this.setState({
        isLoading: false,
        editions: currentProduct,
        error: '',
      });
    } catch (error: any) {
      this.setState({ isLoading: false, error: error.message });
    }
  }

  handleUniqueEdition = async (edition: any) => {
    const { currentProduct } = this.props.general.appState;
    this.setState({ isLoading: true });

    try {
      if (
        this.props.general.appState.offer === null &&
        currentProduct !== null
      ) {
        await this.props.selectOffer(
          currentProduct.channelDto.id,
          currentProduct.channelDto.name
        );
      }
      this.props.selectEdition(
        edition.editionDto[0].id,
        edition.editionDto[0].name
      );
      browserHistory.push(`/pay${getUrlParamsFromLs()}`);
    } catch (error: any) {
      this.setState({
        intentError: error.message,
        isLoading: false,
      });
    }
  };

  handleSelectEdition = async (editionId: number) => {
    const { editions } = this.state;
    const { currentProduct, offer } = this.props.general.appState;

    this.setState({ isLoading: true });

    try {
      if (offer === null && currentProduct !== null) {
        await this.props.selectOffer(
          currentProduct.channelDto.id,
          currentProduct.channelDto.name
        );
      }

      this.props.selectEdition(
        editionId,
        editions.editionDto[editionId - 1].name
      );

      browserHistory.push(USER_LOGIN_PAGE);
      // browserHistory.push(`/pay${getUrlParamsFromLs()}`);
    } catch (error: any) {
      this.setState({
        intentError: error.message,
        isLoading: false,
      });
    }
  };

  onKeyDown = (id: any, event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.handleSelectEdition(id);
    }
  };

  render() {
    const { isLoading, editions, error, intentError } = this.state;
    const { offer, duration, productID, brand } = this.props.general.appState;

    if (
      (offer === null && productID === null) ||
      (duration === null && productID === null)
    ) {
      return <Redirect to="/" />;
    }
    if (isLoading) {
      return <LoadingScreen customColor={brand.thirdColor} size={60} />;
    }
    if (error !== '') {
      return (
        <Message className="intent_message" intent="DANGER" message={error} />
      );
    }

    return (
      <div className="step_formule_wrapper">
        {intentError !== '' && (
          <Message
            className="intent_message"
            intent="DANGER"
            message={intentError}
          />
        )}

        {editions.editionDto.map((edition: any) => (
          <div
            onKeyDown={(e) => this.onKeyDown(edition.id, e)}
            onClick={() => this.handleSelectEdition(edition.id)}
            key={`formule-wrapper-${edition.id}`}
            role="presentation"
            className="step_formule"
          >
            <div className="detail_container">
              <h3 className="name">{edition.name}</h3>
            </div>
            <img alt="" src={icon} className="choose" />
          </div>
        ))}
        <a
          href="https://www.google.com/maps/d/u/0/viewer?mid=1kivqqFFVv4AxxXT0bUEnxA6l3FE&ll=50.30483311024162%2C4.709662042968841&z=8"
          target="_blank"
          rel="noreferrer"
          className="font-medium text-gray-500 mt-4 hover:underline"
        >
          Besoin d&apos;aide pour choisir votre édition ?
        </a>
      </div>
    );
  }
}

export default Edition;
