import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { GeneralState, ProductType } from '../../reducers/entities/general';
import './freeCampaign.css';
import { HOME_PAGE } from '../../utils/routes';
import LoadingScreen from '../../components/LoadingScreen';
import { getFreeCampaign } from '../../actions/views/home';
import { UserState } from '../../reducers/entities/user';
import { AddUserType } from '../../actions/userActions';
import { checkUserAccessListType } from '../../actions/views/pay';
import {
  ConfirmTrialCampaignType,
  CreateOrderType,
  createTransactionType,
} from '../../actions/views/trialCampaign';
import { PaymentStatus } from '../../utils/model';
import { getCurrentBrand } from '../../utils/brands';

interface IFreeCampaignProps {
  general: GeneralState;
  user: UserState;
  openLogginModal: (e: React.MouseEvent) => void;
  actions: {
    addUser: AddUserType;
    createOrder: CreateOrderType;
    createTransaction: createTransactionType;
    checkUserAccessList: checkUserAccessListType;
    confirmTrialCampaign: ConfirmTrialCampaignType;
  };
}

interface IfreeCampaign {
  id: number;
  brandId: number;
  productId: number;
  name: string;
  online: boolean;
  activeForm: Date;
  activeTo: Date;
  title: string;
  subTitle: string;
  mainImage: string;
  content: string;
  relatedOffer: string;
  partnerLogo: string;
  product: ProductType;
}

const FreeCampaign = (props: IFreeCampaignProps) => {
  const dispatch = useDispatch();
  const {
    createOrder,
    createTransaction,
    checkUserAccessList,
    confirmTrialCampaign,
  } = props.actions;
  const { general, openLogginModal, user } = props;
  const { brand } = general.appState;
  const { freeCampaignName } = useParams<{ freeCampaignName: string }>();
  const [freeCampaign, setFreeCampaign] = useState<IfreeCampaign | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isAlreadySubscribed, setIsAlreadySubscribed] = useState<
    boolean | null
  >(null);
  const [clickActivation, setClickActivation] = useState<boolean>(false);
  const [isError, setIsError] = useState<string | null>(null);
  const fetchCampaign = async () => {
    if (freeCampaignName != null) {
      await dispatch(getFreeCampaign(general.appState.brand, freeCampaignName))
        .then((fc: IfreeCampaign) => {
          setFreeCampaign(fc);
          setIsError(null);
        })
        .then(setIsLoading(false))
        .catch((error: any) => setIsError(error.message));
    }
  };

  useEffect(() => {
    fetchCampaign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [general.appState.brand.id]);

  useEffect(() => {
    async function manageOrder() {
      const order = await createOrder({
        productId: freeCampaign?.product?.id,
        resetThirdParty: true,
      });
      const createdTransaction = await createTransaction({
        orderId: order.id,
      });
      const transaction = await confirmTrialCampaign(createdTransaction.id);
      if (transaction.payementStatus === PaymentStatus.Succeded)
        setIsSuccess(true);
    }
    if (
      user.currentUser.isAuth &&
      freeCampaign?.product?.id !== null &&
      freeCampaign?.product?.id !== undefined &&
      isAlreadySubscribed === false &&
      isSuccess === false &&
      clickActivation
    ) {
      manageOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAlreadySubscribed]);

  useEffect(() => {
    async function checkAccessList() {
      if (freeCampaign?.product?.id != null && user.currentUser.isAuth) {
        const accessList = await checkUserAccessList(freeCampaign?.product?.id);
        if (accessList != null) {
          if (
            accessList.productResourceId != null &&
            accessList.userResourceId != null
          ) {
            if (
              accessList.productResourceId === accessList.userResourceId &&
              accessList.userResourceExpireDate != null
            ) {
              if (
                moment(new Date()).add(30, 'days').date <=
                moment(accessList.userResourceExpireDate).date
              ) {
                setIsAlreadySubscribed(true);
                return;
              }
            }
          }
        }
        setIsAlreadySubscribed(false);
      }
    }
    if (user.currentUser.isAuth) {
      checkAccessList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.currentUser.isAuth, freeCampaign]);

  const handleActivation = async (e: any) => {
    setClickActivation(true);
    if (!user.currentUser.isAuth) {
      await openLogginModal(e);
    }
  };

  return (
    <div className="step_formule_wrapper trialCampaignPage">
      <div className="offer-container">
        {isLoading && (
          <LoadingScreen
            customColor={general.appState.brand.thirdColor}
            size={60}
          />
        )}
        {!isAlreadySubscribed &&
          !isSuccess &&
          freeCampaign &&
          isError === null &&
          !isLoading && (
            <section id="offer-screen">
              <h1 className="ap-Title custom-title">{freeCampaign?.title}</h1>
              <section className="flex mx-auto justify-center">
                <div className="triaCampaignButton">
                  <div>
                    <h2>
                      Accédez gratuitement à notre contenu abonné pendant{' '}
                      {freeCampaign?.product?.duration} mois
                    </h2>
                    <button onClick={handleActivation} type="button">
                      j&apos;active
                    </button>
                  </div>
                </div>
              </section>
              <div id="piano-offer" className="mt-4" />
              <div className="wrapper">
                <div className="redaction-container relative">
                  <img
                    src={freeCampaign?.mainImage}
                    height="200"
                    width="150"
                    alt="Main"
                  />
                  <h2 className="text-md mb-2">{freeCampaign?.subTitle}</h2>
                  <div className="piano-offer-content">
                    {freeCampaign?.content != null &&
                      ReactHtmlParser(freeCampaign.content)}
                  </div>
                  <div className="disclaimer-container">
                    <p className="font-bold mb-8">
                      N&apos;oubliez pas, grâce à votre accès gratuit vous
                      bénéficiez :
                    </p>
                    {/* <ul>
                    {freeCampaign?.product?.channelDto?.infos.map((d: any) => (
                      <CheckedBullet
                        insidePrimary={
                          freeCampaign?.product?.channelDto.hightlight
                        }
                        checked={d.checked}
                        key={`${freeCampaign?.product?.channelDto.name}-option-${d.content}`}
                      >
                        {d.content}
                      </CheckedBullet>
                    ))}
                  </ul> */}
                    <ul>
                      <li>
                        D&apos;un accès illimité à tous les articles du site
                      </li>
                      <li>
                        D&apos;un accès au journal numérique (PDF ou enrichi) du
                        lundi au samedi
                      </li>
                      <li>
                        D&apos;un accès illimité à tous les podcasts de la
                        rédaction
                      </li>
                      <li>
                        Vous intégrerez également notre communauté
                        d&apos;abonnés et bénéficierez de nombreux avantages
                        tels que des concours, réductions, invitations, ...
                      </li>
                    </ul>
                    <p className="faq font-bold text-center text-xl">
                      Vous avez des questions ?{' '}
                      <a
                        href={`${getCurrentBrand(brand.name).uri}/page/faq/`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Consultez notre FAQ
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          )}
        {isSuccess && !isAlreadySubscribed && (
          <section id="subscriber-screen">
            <div className="wrapper">
              <h1 className="ap-Title custom-title">
                Félicitations, vous faites à présent partie de notre communauté.
              </h1>
              <div className="disclaimer-container">
                <p className="text-center">
                  Profitez dès à présent d&apos;un confort de lecture en vous
                  rendant sur notre site et configurez vos préférences de
                  lecture pour ne rien rater de l&apos;actualité qui vous
                  intéresse.
                </p>
                <a
                  href={getCurrentBrand(brand.name).uri}
                  className="ap-Button--pay ap-PrimaryMenu-button custom-button"
                >
                  <span className="ap-PrimaryMenu-text">Vers le site</span>
                </a>
              </div>
            </div>
          </section>
        )}
        {isAlreadySubscribed && (
          <section id="subscriber-screen">
            <div className="wrapper">
              <h1 className="ap-Title custom-title">
                Désolé, vous avez déjà un abonnement actif.
              </h1>
              <div className="disclaimer-container">
                {freeCampaign?.relatedOffer !== null &&
                  freeCampaign?.relatedOffer !== undefined && (
                    <>
                      <p className="text-center">
                        Ne vous arrêtez pas en si bon chemin et{' '}
                        <b>découvrez sans plus attendre notre autre cadeau :</b>
                      </p>
                      <a
                        href={freeCampaign?.relatedOffer}
                        className="ap-Button--pay ap-PrimaryMenu-button custom-button"
                      >
                        <span className="ap-PrimaryMenu-text">
                          J&apos;active mon autre cadeau
                        </span>
                      </a>
                    </>
                  )}
              </div>
            </div>
          </section>
        )}
        {isError !== null && (
          <section id="subscriber-screen">
            <div className="wrapper">
              <h1 className="ap-Title custom-title">{isError}</h1>
            </div>
          </section>
        )}
        {isError === null && freeCampaign && (
          <img
            src={freeCampaign?.partnerLogo}
            height="200"
            width="100"
            className="m-auto my-4"
            alt="Partner Logo"
          />
        )}
        <section className="flex mx-auto justify-center">
          <Link className="temporaryOffers-backLink" to={HOME_PAGE}>
            Découvrez toutes nos offres
          </Link>
        </section>
      </div>
    </div>
  );
};

export default FreeCampaign;
