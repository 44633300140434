import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import browserHistory from '../../utils/browser_history';
import { HOME_PAGE } from '../../utils/routes';
import Message from '../../components/Message';

interface ILocationState extends Location {
  state?: {
    message: string;
  };
}

interface IErrorPageProps {
  location: ILocationState;
}

const ErrorPage = ({ location }: IErrorPageProps) => {
  useEffect(() => {
    const message = location?.state?.message;
    if (!message) {
      browserHistory.push(HOME_PAGE);
    }
  });

  return (
    <div className="step_formule_wrapper mt-8">
      {location.state?.message && (
        <Message
          intent="DANGER"
          className="auth-error"
          message={location.state?.message}
        />
      )}

      <Link
        to={HOME_PAGE}
        className="px-4 py-2 text-white bg-gray-900 font-medium rounded mt-4 mx-auto hover:bg-black transition duration-300"
      >
        Découvrez toutes nos offres
      </Link>
    </div>
  );
};

export default ErrorPage;
