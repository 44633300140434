import React from 'react';
import { ReactComponent as Marker } from '../../../images/icons/marker.svg';

interface IMarkerIconProps {
  selected: boolean;
}

const MarkerIcon = ({ selected = false }: IMarkerIconProps) => (
  <Marker style={{ fill: selected ? 'var(--secondary)' : '#3FB1CE' }} />
);

export default MarkerIcon;
