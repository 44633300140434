import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Paper } from '@material-ui/core';
import fetch from 'isomorphic-fetch';
import { useDispatch } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import InputError from '../InputError';
import './PickUp.css';
import MarkerIcon from '../Icons/MarkerIcon';
import GlassIcon from '../Icons/GlassIcon';
import { getAPIUrl } from '../../actions/generalActions';
import { addAddressInfo } from '../../actions/views/delivery';

interface PickUpAddressResponse {
  id: number;
  address: string;
  city: string;
  latitude: number;
  longitude: number;
  name: string;
  postalCode: number;
}

interface PickUpAddress extends PickUpAddressResponse {
  selected: boolean;
}

type PickUpFormValues = {
  postcode: string;
};

interface IPickUpProps {
  done: () => void;
  intent: string;
}

const PickUp = ({ done, intent }: IPickUpProps) => {
  const dispatch = useDispatch();

  const { handleSubmit, register, errors } = useForm<PickUpFormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      postcode: '',
    },
  });

  const [map, setMap] = useState<mapboxgl.Map>();
  const [markers, setMarkers] = useState<mapboxgl.Marker[]>();
  const mapRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

  const [libraries, setLibraries] = useState<PickUpAddress[]>([]);
  // const [saving, setSaving] = useState<boolean>(false);
  const [askOnce, setAskOnce] = useState<boolean>(false);

  function cleanMarkers() {
    if (markers !== undefined && markers.length > 0) {
      setMarkers([]);
      markers.forEach((marker) => marker.remove());
    }
  }
  useEffect(() => {
    if (mapRef.current && !map) {
      setMap(
        new mapboxgl.Map({
          container: mapRef.current,
          accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
          style: 'mapbox://styles/mapbox/streets-v12?optimize=true',
          center: [libraries?.[0].longitude, libraries?.[0]?.latitude],
          minZoom: 7,
          maxZoom: 15,
          zoom: 9,
        })
      );
    }
  }, [libraries, map, mapRef]);
  useEffect(() => {
    if (map !== undefined) {
      if (markers !== undefined && markers.length > 0) {
        markers?.forEach(
          (x) =>
            x.addTo(map) &&
            x.getElement().addEventListener('click', () => {
              setLibraries(
                libraries
                  .map((i) =>
                    i.latitude === x.getLngLat().lat &&
                    i.longitude === x.getLngLat().lng
                      ? { ...i, selected: true }
                      : { ...i, selected: false }
                  )
                  .sort((a, b) => Number(b.selected) - Number(a.selected))
              );
            })
        );
        map.setCenter(markers?.[0].getLngLat());
      }
    }
  }, [libraries, map, markers]);

  useEffect(() => {
    if (map !== undefined) {
      const currentMarkers: React.SetStateAction<
        mapboxgl.Marker[] | undefined
      > = [];
      libraries.forEach((x) => {
        const marker = new mapboxgl.Marker({
          ...(x.selected ? { color: 'var(--secondary)' } : {}),
        })
          .setLngLat([x.longitude, x.latitude])
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }).setHTML(`<h3>${x.name}</h3>`)
          );
        currentMarkers.push(marker);
      });
      setMarkers(currentMarkers);
    }
  }, [libraries, map]);

  const onSubmit = handleSubmit(async (data) => {
    const resp = await fetch(
      `${getAPIUrl()}/BookSellers/booksellers?search=${data.postcode}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }
    );

    const response = await resp.json();
    if (!resp.ok) throw new Error(response.message);

    setAskOnce(true);
    cleanMarkers();
    setLibraries(
      response.map((library: PickUpAddressResponse) => ({
        ...library,
        selected: false,
      }))
    );

    if (libraries.length === 0) {
      setMap(undefined);
    }
  });

  const submitLibrary = async () => {
    const library = libraries.find((l) => l.selected);
    if (!library) {
      return;
    }

    // setSaving(true);
    await dispatch(
      await addAddressInfo(
        'library',
        {
          CompanyName: library.name,
          address: {
            street: library.address,
            postalCode: library.postalCode,
            houseNumber: 0,
            postalBox: '',
            city: library.city,
            country: {
              name: 'Belgique',
              code: 'BE',
            },
          },
        },
        String(intent)
      )
    );

    done();
    // setSaving(false);
  };

  return (
    <>
      <form className="pickupForm" onSubmit={onSubmit}>
        <label htmlFor="postcode">
          Où cherchez-vous un point de vente&nbsp;?
        </label>
        <section className="pickupForm-line">
          <input
            id="postcode"
            className="input"
            name="postcode"
            aria-required="true"
            aria-label="Code postal"
            aria-labelledby="postcode"
            type="text"
            placeholder="Entrez votre code postal"
            onChange={(e) => {
              if (e.target.value.length >= 4) {
                onSubmit(e);
              }
            }}
            ref={register({
              required: 'Le code postal est requis',
            })}
          />
          <button type="submit">
            <GlassIcon />
          </button>
        </section>
        <InputError message={errors?.postcode?.message} />
      </form>
      {libraries.length === 0 && askOnce && (
        <div className="text-center">
          <h4 className="font-black">Pas de résultat.</h4>
          <p>
            Aucun point de vente ne semble être disponible dans la zone
            recherchée.
            <br />
            Veuillez modifier votre recherche ou prendre contact avec notre
            service clientèle.
          </p>
        </div>
      )}

      {libraries.length > 0 && askOnce && (
        <>
          <div ref={mapRef} className="map-container" />
          <div className="libraries">
            {libraries.map((library: PickUpAddress) => (
              <Paper
                key={library.id}
                className="libraries-item"
                onClick={() => {
                  setLibraries(
                    libraries.map((i) =>
                      i.id === library.id
                        ? { ...i, selected: !library.selected }
                        : { ...i, selected: false }
                    )
                  );
                }}
              >
                <MarkerIcon selected={library.selected} />
                <section className="libraries-item--sub">
                  <section>
                    <h3>{library.name}</h3>
                    <p>
                      {library.address}
                      <br />
                      {library.postalCode} {library.city}
                    </p>
                  </section>
                  <button
                    className={
                      library.selected
                        ? 'libraries-item-selected'
                        : 'libraries-item-unselected'
                    }
                    type="button"
                  />
                </section>
              </Paper>
            ))}
            <button
              type="button"
              className="pickUpConfirm"
              disabled={!libraries.find((l) => l.selected)}
              onClick={submitLibrary}
            >
              Choisir
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default PickUp;
