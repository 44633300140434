import React from 'react';
import { ReactComponent as Cross } from '../../../images/icons/cross.svg';

interface ICrossIconProps {
  className?: string;
}

const CrossIcon = ({ className }: ICrossIconProps) => (
  <Cross className={`w-8 h-8 ${className}`} style={{ fill: '#0D0D0D' }} />
);

export default CrossIcon;
