import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GeneralTypes } from '../types';
import { log, getAPIUrl } from '../generalActions';
import { AppAction } from '../../reducers/entities/general';

export type SelectGiftType = (gift: string) => void;

export const selectGift: SelectGiftType = (gift) => (dispatch: any) => {
  localStorage.setItem('gift', gift.toString());
  dispatch({ type: GeneralTypes.SELECT_GIFT, data: gift });
  // const { appActions } = getState().entities.general;
  // const { uuid } = getState().entities.user.currentUser;
  // if (appActions !== null && uuid !== '')
  //   dispatch(
  //     log({
  //       actionDto: appActions.find(
  //         (a: AppAction) => a.definition === 'SelectGift'
  //       ),
  //       uuid,
  //       result: gift,
  //     })
  //   );
};

// Fetch gifts call
export type GetGiftType = () => any;
export const getGifts: GetGiftType =
  () => async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) => {
    try {
      const resp = await fetch(
        `${getAPIUrl()}/Product/${
          getState().entities.general.appState.productID
        }`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      throw error;
    }
  };

// Fetch gifts call
export type CheckHasGiftType = () => any;
export const checkHasGift: CheckHasGiftType =
  () => async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) => {
    try {
      const resp = await fetch(
        `${getAPIUrl()}/Gifts/${getState().entities.general.appState.campaign}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );
      const data = await resp.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

export type SetHasGiftType = (gift: string) => void;
export const setHasGift: SetHasGiftType =
  (hasGift) => (dispatch: any, getState: any) => {
    localStorage.setItem('hasGift', hasGift.toString());
    dispatch({ type: GeneralTypes.SET_HAS_GIFT, data: hasGift });
    const { appActions } = getState().entities.general;
    const { uuid } = getState().entities.user.currentUser;
    if (appActions !== null && uuid !== '')
      dispatch(
        log({
          actionDto: appActions.find(
            (a: AppAction) => a.definition === 'SetHasGift'
          ),
          uuid,
          result: hasGift,
        })
      );
  };

export type SetNoGiftType = () => void;
export const setNoGift: SetNoGiftType = () => (dispatch: any) => {
  localStorage.removeItem('hasGift');
  dispatch({ type: GeneralTypes.SET_HAS_GIFT, data: null });
};
