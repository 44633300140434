import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { addUser, logout } from '../../actions/userActions';
import { createOrder } from '../../actions/views/pay';
import { getOrderInfo } from '../../actions/views/delivery';
import { RootState } from '../../reducers';
import TemporaryOffers from './TemporaryOffers';

const mapStateToProps = (state: RootState) => {
  const { general } = state.entities;
  return { general };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  dispatch,
  actions: bindActionCreators(
    {
      logout,
      getOrderInfo,
      addUser,
      createOrder,
    },
    dispatch
  ),
});

export type BillingProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TemporaryOffers);
