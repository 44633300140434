import { connect } from 'react-redux';
import Finished from './Finished';
import { RootState } from '../../reducers';

const mapStateToProps = (state: RootState) => {
  const { user, general } = state.entities;
  return {
    user,
    general,
  };
};

export type FinishedProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, null)(Finished);
