import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper, { PaperProps } from '@material-ui/core/Paper';

const styles = () => ({
  root: { borderRadius: 10, border: '1px solid #D3D3D3' },
});

function CustomPaper(props: PaperProps) {
  const { className, classes, ...others } = props;
  return (
    <Paper
      {...others}
      className={`${className || ''} ${classes ? classes.root : ''} home offer`}
    >
      {props.children}
    </Paper>
  );
}

export default withStyles(styles)(CustomPaper);
