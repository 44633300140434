import React, { PropsWithChildren } from 'react';

interface IRowProps {
  className?: string;
}

const Row = ({ children, className }: PropsWithChildren<IRowProps>) => (
  <section className={`flex flex-row w-full ${className}`}>{children}</section>
);

export const RowHalf = ({ children }: PropsWithChildren<IRowProps>) => (
  <section className="w-1/2">{children}</section>
);

export default Row;
