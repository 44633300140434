import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  BrandType,
  ProductBullet,
  ProductType,
} from '../../reducers/entities/general';
import GiftIcon from '../Icons/GiftIcon';
import CardSeparator from '../CardSeparator';
import CheckedBullet from '../CheckedBullet';
import SecureSpace from '../SecureSpace';

import './selectedProduct.css';
import { DURATION_PAGE, FINISHED_PAGE } from '../../utils/routes';
import { formatPrice, pricePerWeek } from '../../utils/price';
import TrophyIcon from '../Icons/TrophyIcon';
import { MAIN_BRANDS_NAME } from '../../utils/brands';

interface ISelectedProductProps {
  brand: BrandType;
  currentProduct: Partial<ProductType>;
  gift?: string | null;
  className?: string;
  currentPath?: string;
}

const SelectedProduct = ({
  brand,
  currentProduct,
  gift = '',
  className = '',
  currentPath = '',
}: ISelectedProductProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loyaltyPoints = Math.floor(
    (currentProduct?.price || 0) * (brand.ratioLoyalty || 0)
  );

  return (
    <div className={`selectedProduct ${className}`}>
      <strong className="selectedProduct-header">
        {window.location.pathname === FINISHED_PAGE
          ? 'Résumé de votre commande'
          : 'Aperçu de votre choix'}
      </strong>

      <h3 className="selectedProduct-title">
        {currentProduct?.channelDto?.name.toLowerCase()}
      </h3>

      {currentPath !== DURATION_PAGE && (
        <>
          <h4 className="selectedProduct-duration">
            {typeof currentProduct?.price === 'number' && (
              <span className="font-black">{currentProduct?.name}</span>
            )}
          </h4>
          <p className="selectedProduct-price">
            {typeof currentProduct?.price === 'number' &&
              `${formatPrice(currentProduct.price)}€`}
            {currentProduct.pricePerWeek && (
              <>
                &nbsp;soit&nbsp;
                <span
                  className="pricePerWeek"
                  style={{ color: 'var(--primary)' }}
                >
                  {pricePerWeek(currentProduct.price, currentProduct.duration)}€
                  / semaine
                </span>
              </>
            )}
          </p>
        </>
      )}

      <ul>
        {gift && (
          <li className="selectedProduct-gifts">
            <GiftIcon className="giftIcon" primary />{' '}
            <span>Cadeau&nbsp;: {gift}</span>
          </li>
        )}
        {MAIN_BRANDS_NAME.includes(brand.name) &&
          currentPath !== DURATION_PAGE &&
          loyaltyPoints > 0 && (
            <li className="selectedProduct-gifts">
              <TrophyIcon />
              <span>
                {loyaltyPoints}
                &nbsp;points de fidélité
              </span>
            </li>
          )}

        <CardSeparator />
      </ul>

      {(currentProduct?.channelDto?.infos || []).length > 0 && (
        <>
          <ul className="selectedProduct-list">
            {(currentProduct?.channelDto?.infos || []).map(
              (d: ProductBullet) => (
                <CheckedBullet
                  checked={d.checked}
                  key={`${currentProduct?.channelDto?.name}-selected-${d.content}`}
                  className="selectedProduct-list--element"
                >
                  {ReactHtmlParser(d.content)}
                </CheckedBullet>
              )
            )}
          </ul>
          <CardSeparator />
        </>
      )}

      <SecureSpace className="justify-center" />
    </div>
  );
};

export default SelectedProduct;
