import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GeneralTypes } from '../types';
import { UserUpdateType, UserUpdateTypeShort } from '../userActions';
import { fetchWithCredentials, getAPIUrl } from '../generalActions';

export interface IAddressSuggestion {
  latitude: number;
  longitude: number;
  coordinateSystem: string;
  perspectiveCode: string;
  reliability: number;
  detectedLanguage: string;
  province: string;
  string: string;
  houseNumber: string;
  searchBarString: string;
  streetName: string;
  municipalityName: string;
  postalCode: string;
}

export const getAddressFullSuggestionList = async (
  value: string,
  increment: number = 1,
  countryCode: string = 'BE'
): Promise<IAddressSuggestion[]> => {
  const resp = await fetch(
    `${getAPIUrl()}/Address/Country/${countryCode}/Streets?search=${value}&seq=${increment}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    }
  );

  const data = await resp.json();
  if (!resp.ok) {
    throw new Error(data.message);
  }

  return (data?.response?.topSuggestions || []).map(
    ({ address }: any) => address
  );
};

export type GetAddressCountriesSuggestionListType = (
  data: Number | string
) => void;
export const getAddressCountriesSuggestionList: GetAddressCountriesSuggestionListType =
  async (value) => {
    try {
      const resp = await fetch(
        `${getAPIUrl()}/Address/Countries?search=${value}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );
      const data = await resp.json();
      if (!resp.ok) {
        throw new Error(data.message);
      }
      return data;
    } catch (error) {
      throw error;
    }
  };

export type GetOrderInfoType = (orderId: string) => any;
export const getOrderInfo: GetOrderInfoType =
  (orderId) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      const resp = await dispatch(
        fetchWithCredentials(
          `${getAPIUrl()}/Order/GetOrderByCustomer?orderId=${orderId}`,
          {
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          }
        )
      );

      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      if (data?.product !== undefined) {
        dispatch({ type: GeneralTypes.SELECT_PRODUCT, data: data.productId });
        dispatch({ type: GeneralTypes.SET_PRODUCT, data: data.product });
        if (
          data?.product?.editionDto !== null &&
          data?.product?.editionDto !== undefined &&
          data?.product?.editionDto.length > 0
        ) {
          const selectedEdition = data.product.editionDto.find(
            (e: any) => e.name === data.editionName
          );
          const editionId =
            selectedEdition !== undefined ? selectedEdition.id : null;
          dispatch({ type: GeneralTypes.SELECT_EDITION, data: editionId });
        }
      }
      // Dispatch all order info so Delivery component works like in a normal flow
      dispatch({
        type: GeneralTypes.UPDATE_INTENT_SUCCESS,
        data: orderId,
      });
      return data;
    } catch (error) {
      throw error;
    }
  };

export type FinishDeliveryType = (
  user: UserUpdateType,
  selectedDelay: Date,
  fromUserInfo: boolean
) => any;
export const finishDelivery: FinishDeliveryType =
  (user, selectedDelay, fromUserInfo) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) => {
    try {
      if (!fromUserInfo) {
        await dispatch(
          fetchWithCredentials(`${getAPIUrl()}/Pay/Confirmation`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({
              Id: getState().entities.general.appState.transaction,
              SelectedDelay: selectedDelay,
            }),
          })
        );
      }
      dispatch({
        type: GeneralTypes.DELIVERY_SUCCESS,
        data: { delivery: user.delivery, user },
      });
      return { ok: true };
    } catch (error) {
      throw error;
    }
  };

export type addAddressInfoType = (
  type: string,
  user: UserUpdateType | UserUpdateTypeShort,
  orderId: string
) => any;
export const addAddressInfo: addAddressInfoType =
  (type, user, orderId) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      const parties: any = {
        delivery: {
          path: 'AddDeliveryInfos',
          partyType: '',
        },
        billing: {
          path: 'AddInvoiceInfos',
          partyType: '',
        },
        library: {
          path: 'AddBookSellerInfos',
          partyType: '',
        },
        personal: {
          path: 'UpdatePersonInfos',
          partyType: '',
        },
        bookSeller: {
          path: 'AddBookSellerCheckInfos',
          partyType: '',
        },
      };
      const resp = await dispatch(
        fetchWithCredentials(
          parties[type].path === parties.personal.path
            ? `${getAPIUrl()}/Customers/${parties[type].path}`
            : `${getAPIUrl()}/Customers/${
                parties[type].path
              }?orderId=${orderId}`,
          {
            method: 'POST',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({
              ...user,
              CompanyName: user.CompanyName !== '' ? user.CompanyName : 'none',
              VATNumber: user.VATNumber || '',
              ...(Object.keys(user).includes('address') && {
                address: (user as UserUpdateType)?.address,
              }),
            }),
          }
        )
      );

      const data = await resp.json();
      if (!resp.ok) {
        throw new Error(data.message);
      }

      return data;
    } catch (error) {
      throw error;
    }
  };
