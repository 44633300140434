import { bindActionCreators, AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Root from './Root';
import { GetBrand } from '../../actions/views/root';
import {
  login,
  initApp,
  register,
  checkEmail,
  validateCode,
  resendValidationCode,
  logout,
  addUser,
} from '../../actions/userActions';
import { RootState } from '../../reducers';

const mapStateToProps = (state: RootState) => {
  const { user, general } = state.entities;
  const { router } = state;

  return {
    user,
    general,
    currentPath: router.location.pathname,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  dispatch,
  actions: bindActionCreators(
    {
      login,
      register,
      addUser,
      checkEmail,
      validateCode,
      resendValidationCode,
      logout,
      initApp,
      GetBrand,
    },
    dispatch
  ),
});

export type RootProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<any>;

export default connect(mapStateToProps, mapDispatchToProps)(Root);
