import React from 'react';
import { ReactComponent as Gift } from '../../../images/icons/gift.svg';

interface IGiftIconProps {
  primary?: boolean;
  className?: string;
}

const GiftIcon = ({ className, primary }: IGiftIconProps) => (
  <Gift
    className={className}
    style={{ fill: primary ? 'var(--primary)' : 'white' }}
  />
);

export default GiftIcon;
