import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
// import ReactGA from 'react-ga';
// import TagManager from 'react-gtm-module';
import mapboxgl from 'mapbox-gl';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import Root from './containers/Root';
import configureStore from './store/configureStore';
// import brands, { IBrand } from './utils/brands';

window.cache.canTrack = false;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';

const store = configureStore();

ReactDOM.render(<Root store={store} />, document.getElementById('root'));

serviceWorker.unregister();
