import React from 'react';
import TextField from '@material-ui/core/TextField';

function useCombinedRefs(...refs: any) {
  const targetRef = React.useRef();

  React.useEffect(() => {
    refs.forEach((ref: any) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        // eslint-disable-next-line no-param-reassign
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

const CombinedRefTextField = React.forwardRef((props: any, forwardedRef) => {
  const innerRef = React.useRef(null);
  const combinedRef = useCombinedRefs(forwardedRef, innerRef);

  return (
    <TextField
      {...props}
      fullWidth
      className="TextField"
      aria-required="false"
      autoComplete="nope"
      variant="outlined"
      inputRef={combinedRef}
    />
  );
});

export default CombinedRefTextField;
