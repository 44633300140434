import { StepConnector, withStyles } from '@material-ui/core';
import React from 'react';

function QontoConnector(props: any) {
  const { color, ...other } = props;

  const QontoConnectorr = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 9.5px)',
      right: 'calc(50% + 9.5px)',
    },
    active: {
      '& $line': {
        borderColor: color,
      },
    },
    completed: {
      '& $line': {
        borderColor: color,
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector);

  return <QontoConnectorr {...other} />;
}

export default QontoConnector;
