import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { fetchWithCredentials, getAPIUrl } from '../generalActions';
import { OrderType, TransactionType } from './pay';

export type CreateOrderType = (order: OrderType) => any;
export const createOrder: CreateOrderType =
  (order) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const resp = await dispatch(
      fetchWithCredentials(`${getAPIUrl()}/Pay/CreateOrder`, {
        method: 'POST',
        body: JSON.stringify(order),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
    );

    if (!resp?.ok) {
      throw new Error('Une erreur est survenue, veuillez recharger la page');
    }

    const data = await resp.json();
    return data;
  };

export type createTransactionType = (order: TransactionType) => any;
export const createTransaction: createTransactionType =
  (transaction) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      const resp = await dispatch(
        fetchWithCredentials(
          `${getAPIUrl()}/Pay/${transaction.orderId}/CreateTransaction`,
          {
            method: 'POST',
            body: JSON.stringify({ ...transaction }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          }
        )
      );
      if (!resp.ok) throw new Error(resp.statusText);
      const data = await resp.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

export type ConfirmTrialCampaignType = (transactionId: string) => any;
export const confirmTrialCampaign: ConfirmTrialCampaignType =
  (transactionId) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      if (transactionId === null) {
        throw new Error('Missing transactionId');
      }
      const resp = await dispatch(
        fetchWithCredentials(`${getAPIUrl()}/Pay/ConfirmTrialCampaign`, {
          method: 'POST',
          body: JSON.stringify({
            id: transactionId,
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
      );
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      throw error;
    }
  };
