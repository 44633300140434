import React, { useState, useCallback, useEffect } from 'react';
import { bindActionCreators, AnyAction, Dispatch } from 'redux';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { createPortal } from 'react-dom';
import {
  EnumPaymentServiceProvider,
  PaymentStatus,
} from '../../../utils/model';
import MyWindowPortal from '../../Payment/Select/MyWindowPortal';
import { getAPIUrl } from '../../../actions/generalActions';
import {
  createOrder,
  createTransaction,
  checkTransaction,
  createMollie,
  findPaymentMethods,
} from '../../../actions/views/renewal';
import LoadingScreen from '../../LoadingScreen';
import { RootState } from '../../../reducers';
import CutomButton from '../../Button';

import '../Renewal.css';
import { UserTypes } from '../../../actions/types';
import { PaymentMethod } from '../../../reducers/entities/general';
import AuthPiano from '../../AuthPiano';
import { addUser, logout } from '../../../actions/userActions';
import usePiano from '../../AuthPiano/PianoID';
import SelectedProduct from '../../SelectedProduct';
import browserHistory from '../../../utils/browser_history';
import { FINISHED_PAGE } from '../../../utils/routes';

function PayRenewal({ general, user }: any) {
  const dispatch = useDispatch();

  const { brand, currentProduct, intent } = general.appState;

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [portalOpen, setPortalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [Error, setError] = useState('');
  const [showTransfertModal, setShowTransfertModal] = useState(false);
  const [transaction, setTransaction] = useState<any>(null);
  const [orderError, setOrderError] = useState('');
  const [paymentAlreadyFailed, setPaymentAlreadyFailed] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [logginToken, setLogginToken] = useState('');
  const [popup, setPopup] = useState<any>(null);
  const [url, setUrl] = useState(
    `${getAPIUrl()}/Renewal/GetRenewalIngenicoPage`
  );

  useEffect(() => {
    async function fetchData() {
      const pms: PaymentMethod[] = await findPaymentMethods(dispatch, brand.id);
      setPaymentMethods(
        pms.filter((item: PaymentMethod) =>
          currentProduct.paymentMethodsDto.includes(item.id)
        )
      );
    }
    async function ct() {
      const createdTransaction = await createTransaction(dispatch, {
        orderId: intent,
      });
      await setTransaction(createdTransaction);
    }
    fetchData();
    ct();
  }, [brand, currentProduct.paymentMethodsDto, dispatch, intent]);

  // useEffect(() => {
  //   setCurrentProduct({
  //     channelDto: {
  //       id: 0,
  //       name: decodeURIComponent(escape(nameOffer)),
  //       infos: [],
  //       includePaper: false,
  //     },
  //     name: '',
  //     price: parseInt(price, 10),
  //   });
  //   window.addEventListener('beforeunload', newWindowUnloaded);
  //   return function cleanup() {
  //     window.removeEventListener('beforeunload', newWindowUnloaded);
  //   };
  // }, [newWindowUnloaded]);

  useEffect(
    () => () => {
      if (popup != null) {
        popup.close();
      }
    },
    [popup]
  );

  const handleMolliePayment = async (t: any) => {
    const mollieResponse: any = await createMollie(dispatch, t);
    // eslint-disable-next-line no-underscore-dangle
    const hrefMollie: string = mollieResponse._links.checkout?.href;
    setUrl(hrefMollie);
    setPortalOpen(true);
  };

  const goForPayment = async (pm: PaymentMethod, t: any) => {
    switch (pm.id) {
      case 1:
      case 2:
      case 3:
        setUrl(
          `${url}?&name=${user.lastName}&paymentmethod=${pm.id}&transactionId=${t.id}&refs=testref`
        );
        setPortalOpen(true);
        break;
      case 4:
        setShowTransfertModal(true);
        break;
      case 5:
        if (
          pm.paymentServiceProvidersDTO.id === EnumPaymentServiceProvider.Mollie
        ) {
          handleMolliePayment(t);
        }
        break;
      default:
        setOrderError(
          'Erreur dans la sélection de produit, veuillez recommencer.'
        );
    }
  };

  const handleMethodSelection = async (paymentMethod: PaymentMethod) => {
    try {
      if (!user.currentUser.isAuth) {
        setError('notauth');
        return;
      }
      // if (user.currentUser.email !== email) {
      //   setError('notright');
      //   return;
      // }
      setError('');
      setOrderError('');
      setPaymentSuccess(false);

      // if (price === null || email === null || nameOffer === null) {
      //   return;
      // }
      await goForPayment(paymentMethod, transaction);
    } catch (error: any) {
      setIsLoading(false);
      setOrderError(error.message);
    }
  };

  const { handleLogout } = usePiano({ action: 'logout' }) || {};

  const Logout = async () => {
    await handleLogout();
    await dispatch(logout());
  };

  const handleloggedIn = async (e: any) => {
    if (logginToken !== e.token) {
      setLogginToken(e.token);
      await dispatch({
        type: UserTypes.LOGIN_SUCCESS,
        data: e,
      });
    }
  };

  const newWindowUnloaded = useCallback(async () => {
    setPortalOpen(false);
    setUrl(`${getAPIUrl()}/Renewal/GetRenewalIngenicoPage`);
    if (transaction === null) {
      setOrderError('Il y a eu un problème, veuillez réessayer.');
      return;
    }

    const ct = await checkTransaction(dispatch, transaction.id);
    switch (ct) {
      case PaymentStatus.Succeded:
        // setPaymentSuccess(true);
        await browserHistory.push(FINISHED_PAGE);
        break;
      case PaymentStatus.Initialized:
        setOrderError('Votre paiement a échoué, veuillez réessayer.');
        break;
      case PaymentStatus.Pending:
        if (paymentAlreadyFailed) {
          setPaymentAlreadyFailed(true);
          newWindowUnloaded();
        } else {
          setOrderError('Votre paiement a échoué, veuillez réessayer.');
        }
        break;
      case PaymentStatus.Failed:
        setOrderError('Votre paiement a échoué, veuillez réessayer.');
        break;
      case PaymentStatus.Refunded:
        setOrderError(
          'Votre paiement a échoué, nous vous avons remboursé, veuillez réessayer.'
        );
        break;
      default:
        setOrderError('Il y a eu un problème, veuillez réessayer.');
    }
    setIsLoading(false);
  }, [dispatch, paymentAlreadyFailed, transaction]);

  // if (ref === null || price === null || numAbo === null || nameOffer === null) {
  //   return <Redirect to={HOME_PAGE} />;
  // }
  // if (currentProduct == null) {
  //   const product: ProductType = {
  //     channelDto: {
  //       id: 0,
  //       name: '',
  //       infos: [],
  //       includePaper: false,
  //     },
  //     deliveryOptionsDto: [],
  //     duration: 0,
  //     durationName: '',
  //     editionDto: [],
  //     id: 0,
  //     editionID: 0,
  //     name: '',
  //     online: false,
  //     price: parseFloat(
  //       currentProduct.price.replace(/\./g, '').replace(',', '.')
  //     ),
  //     saturdayPaper: false,
  //     tempAccess: '',
  //     paymentMethodsDto: [],
  //     typeDto: {
  //       id: 0,
  //       name: '',
  //     },
  //     productInfos: currentProduct.nameOffer,
  //     thanksYouMessage: '',
  //     conditions: '',
  //     gifts: '',
  //   };
  //   dispatch({ type: GeneralTypes.SET_RENEWAL, data: product });
  // }

  const renderLoading = () => (
    <div className="loader">
      <LoadingScreen />
      <p className="text-center">
        En attente de votre paiement, ne pas fermer cette page.
        <br />
        Pour finaliser votre commande, veuillez procéder à la transaction
        sécurisée dans la fenêtre de paiement.
      </p>
    </div>
  );

  const renderPaymentSucceed = () => (
    <>
      <h1>Paiement réussi!</h1>
      <p>Merci pour votre réabonnement</p>
      <CutomButton customColor={brand.thirdColor} color="primary" size="large">
        {brand.finishedText}
      </CutomButton>
    </>
  );

  let alertError: null | string = null;
  let showLogoutAlertButton: boolean = true;
  if (orderError !== '') {
    alertError = orderError;
    showLogoutAlertButton = false;
  } else if (Error === 'notauth' && !user.currentUser.isAuth) {
    alertError = 'Veuillez-vous connecter pour finaliser votre commande.';
    showLogoutAlertButton = false;
  }
  // } else if (
  //   Error === 'notright' &&
  //   user.currentUser.isAuth &&
  //   user.currentUser.email !== email
  // ) {
  //   alertError = `Vous êtes actuellement connecté(e) avec l'adresse ${user.currentUser.email}.
  // Pour finaliser votre abonnement, veuillez vous déconnecter, puis vous connecter avec le compte ${email}.`;
  // }

  const renderResubscribe = () => (
    <div>
      <p>Choisissez votre moyen de paiement sécurisé.</p>
      <div className="formule_wrapper payment">
        <Grid item xs={12} className="renewal_center_box">
          {alertError && (
            <div className="renewal-alert">
              {alertError}
              {showLogoutAlertButton && (
                <button
                  className="btn_logout"
                  onClick={() => Logout()}
                  type="button"
                >
                  Déconnexion
                </button>
              )}
            </div>
          )}
          {!user.currentUser.isAuth && (
            <AuthPiano
              handleloggedIn={async (val: any) => handleloggedIn(val)}
              authType="login"
              asModal={false}
            />
          )}
        </Grid>
        {paymentMethods.map(
          (pm: PaymentMethod) =>
            pm !== undefined && (
              <button
                key={`pay-with-${pm.name}`}
                aria-label={`Payer via ${pm.name}`}
                className={`offer payment ${pm.name ? 'paylabel' : ''}`}
                type="button"
                tabIndex={0}
                onClick={() => handleMethodSelection(pm)}
              >
                <img
                  src={`${process.env.REACT_APP_CDN_URL}/PSP/${pm.icon}`}
                  alt={`Payer via ${pm.name}`}
                />
                {(pm.name === 'Domiciliation' || pm.name === 'Virement') && (
                  <h3>{pm.name}</h3>
                )}
              </button>
            )
        )}
      </div>
    </div>
  );
  const renderPayment = () =>
    paymentSuccess ? renderPaymentSucceed() : renderResubscribe();
  const currentProductPortal = document.getElementById('currentProductPortal');

  if (isLoading) {
    return <div className="app_sub_wrapper renewal">{renderLoading()}</div>;
  }

  return (
    <>
      {renderPayment()}
      {currentProduct &&
        currentProductPortal &&
        createPortal(
          <SelectedProduct
            brand={brand}
            currentProduct={currentProduct}
            className="w-full lg:w-1/2 lg:mt-24"
          />,
          currentProductPortal
        )}
      {portalOpen && (
        <MyWindowPortal
          onUnload={newWindowUnloaded}
          onCreatePopout={(e: any) => setPopup(e)}
          url={`${url}`}
          window={window}
        />
      )}
      <Dialog
        open={showTransfertModal}
        id="modal transfert-renewal-modal"
        onClose={() => setShowTransfertModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="offer-dialog-title">Virement</DialogTitle>
        <DialogContent id="transfert-renewal-content">
          <p>
            Merci de verser le montant de{' '}
            <span className="amount">{currentProduct.price}€</span> sur le
            compte bancaire suivant:
          </p>

          <p className="account-info">
            <span className="bank-account">BE89 3670 0315 5785</span>
            des Editions de l’Avenir SA
            <br />
            Route de Hannut 38
            <br />
            5004 Bouge
          </p>
          <p>
            Veuillez indiquer la communication structurée suivante lors de votre
            paiement :
          </p>
          <p className="account-info">
            {/* <span className="bank-communication">{ref}</span> */}
          </p>
          <p className="bank-footnote">
            Notre banque: ING
            <br />
            rue Godefroid, 54
            <br />
            5000 Namur
            <br />
            IBAN: BE89 3670 0315 5785
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            size="small"
            onClick={() => setShowTransfertModal(false)}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state: RootState) => {
  const { general, user } = state.entities;
  return {
    general,
    user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      createOrder,
      addUser,
      logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PayRenewal);
