import { bindActionCreators, AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Promotion from './Promotion';
import { GetBrand } from '../../actions/views/root';
import { log } from '../../actions/generalActions';
import { RootState } from '../../reducers';

const mapStateToProps = (state: RootState) => {
  const { general } = state.entities;
  return {
    general,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  dispatch,
  actions: bindActionCreators(
    {
      log,
      GetBrand,
    },
    dispatch
  ),
});

export type PromotionProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Promotion);
