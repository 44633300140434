import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Button from '../../components/Button';
import browserHistory from '../../utils/browser_history';
import { getParamInsensitive } from '../../utils/url';
import { GeneralTypes } from '../../actions/types';
import { VoucherProps } from './index';
import LoadingScreen from '../../components/LoadingScreen';
import Message from '../../components/Message';
import './voucher.css';
import Row from '../../components/Row/Row';
import InputError from '../../components/InputError';

interface VoucherFormValues {
  voucher: string;
}

const Voucher = ({ general, actions }: VoucherProps) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [warning, setWarning] = useState<null | string>(null);
  const [error, setError] = useState<null | string>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [channels, setChannels] = useState([]);

  const { handleSubmit, register, errors } = useForm<VoucherFormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      voucher: '',
    },
  });

  useEffect(() => {
    const campaign = getParamInsensitive('campaign');

    dispatch({
      type: GeneralTypes.SET_CAMPAIGN,
      data: {
        campaign: campaign || '',
      },
    });

    dispatch({
      type: GeneralTypes.SET_UTM_DATA,
      data: {
        utm_campaign: getParamInsensitive('utm_campaign') || '',
        utm_content: getParamInsensitive('utm_content') || '',
        utm_source: getParamInsensitive('utm_source') || '',
        utm_medium: getParamInsensitive('utm_medium') || '',
        promocode: getParamInsensitive('promocode') || '',
      },
    });

    dispatch({ type: GeneralTypes.RESET_APP_STATE });

    const fetchData = async () => {
      setLoading(true);
      try {
        const localChannels = await actions.getChannels(
          campaign || '',
          general.appState.brand
        );
        if (localChannels?.length === 0) {
          setWarning('La promotion est incorrecte ou terminée.');
        }

        if (campaign !== null) {
          const gift = actions.checkHasGift();
          if (gift) {
            actions.setHasGift(gift);
          }
        }

        setChannels(localChannels);
      } catch (e: any) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [actions, dispatch, general.appState.brand]);

  const onSubmit = handleSubmit(async (data) => {
    setError(null);
    setLoading(true);

    try {
      await actions.checkVoucher(
        data.voucher.toString().trim().toUpperCase(),
        general.appState.brand.id,
        browserHistory
      );
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  });

  if (loading) {
    return (
      <LoadingScreen
        customColor={general.appState.brand.thirdColor}
        size={60}
      />
    );
  }

  return (
    <main role="list" className="step_formule_wrapper w-full">
      {warning !== null && (
        <Message
          className="intent_message"
          intent="WARNING"
          message={warning}
        />
      )}

      {error?.length && (
        <Message className="intent_message" intent="WARNING" message={error} />
      )}
      <form className="voucher_form" autoComplete="off" onSubmit={onSubmit}>
        <div className="flex flex-col">
          <label htmlFor="voucher">Code promo</label>
          <input
            id="voucher"
            className="input"
            name="voucher"
            aria-required="true"
            aria-label="Code voucher"
            aria-labelledby="voucher"
            type="text"
            placeholder="Entrez votre code promo"
            aria-placeholder="Entrez votre code promo"
            ref={register({
              required: 'Le code promo est requis',
            })}
          />
        </div>
        {errors?.voucher && <InputError message={errors.voucher.message} />}
        <Row>
          <Button
            customColor={general.appState.brand.thirdColor}
            className="button validate"
            color="primary"
            type="submit"
            fullWidth
            size="large"
          >
            Valider
          </Button>
        </Row>
      </form>
    </main>
  );
};

export default Voucher;
