import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  createStateSyncMiddleware,
  // initStateWithPrevTab,
  initMessageListener,
} from 'redux-state-sync';
import { routerMiddleware } from 'connected-react-router';
import rootReducer, { RootState } from '../reducers';
import browserHistory from '../utils/browser_history';

const config = {
  // TOGGLE_TODO will not be triggered in other tabs
  blacklist: [
    'CREATE_TRANSACTION_SUCCESS',
    'UPDATE_INTENT_SUCCESS',
    'CREATE_ORDER_SUCCESS',
    'ADDUSER_SUCCESS',
  ],
};

const configureStore = (preloadedState?: RootState) => {
  const middlewares = [
    thunk,
    createStateSyncMiddleware(config),
    routerMiddleware(browserHistory),
    ...(process.env.NODE_ENV === 'development' ? [createLogger()] : []),
  ];
  // const middlewares = [
  //   thunk,
  //   routerMiddleware(browserHistory),
  //   ...(process.env.NODE_ENV === 'development' ? [createLogger()] : []),
  // ];

  // return createStore(
  //   rootReducer(browserHistory),
  //   preloadedState,
  //   compose(applyMiddleware(...middlewares))
  // );
  const store = createStore(
    rootReducer(browserHistory),
    preloadedState,
    applyMiddleware(...middlewares)
  );
  initMessageListener(store);
  return store;
};

export default configureStore;
