import React from 'react';
import { PaymentMethod } from '../../../reducers/entities/general';

interface IPaymentMethodButtonProps {
  paymentMethod: PaymentMethod;
  withMainBorder?: boolean;
  onKeyDown: (id: any, event: React.KeyboardEvent<HTMLButtonElement>) => void;
  handleMethodSelection: (item: PaymentMethod) => void;
}
const PaymentMethodButton = ({
  paymentMethod,
  withMainBorder,
  onKeyDown,
  handleMethodSelection,
}: IPaymentMethodButtonProps) => (
  // if (paymentMethod == null) return <Redirect to="/" />;

  <button
    key={`pay-with-${paymentMethod.name}`}
    aria-label={`Payer via ${paymentMethod.name}`}
    className={`offer payment paylabel-bordered ${
      paymentMethod.name ? 'paylabel' : ''
    } ${withMainBorder ? 'paylabel-bordered-main' : ''}`}
    type="button"
    tabIndex={0}
    onKeyDown={(e) => onKeyDown(paymentMethod.id, e)}
    onClick={() => handleMethodSelection(paymentMethod)}
  >
    <img
      src={`${process.env.REACT_APP_CDN_URL}/PSP/${paymentMethod.icon}`}
      alt={`Payer via ${paymentMethod.name}`}
    />
    {paymentMethod.name.toLowerCase() === 'virement' && <h3>Virement</h3>}
  </button>
);
export default PaymentMethodButton;
