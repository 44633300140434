import { BrandType } from '../../reducers/entities/general';
import addScript from '../../utils/addScript';
import setPianoConsent, { pianoConsentInit } from './pianoConsent';

declare global {
  interface Window {
    tp: any;
    didomiOnReady: any;
    pdl: any;
  }
}

interface IPianoUris {
  sandbox: string;
  production: string;
}

const PIANO_URIS: IPianoUris = {
  sandbox: 'https://sandbox.tinypass.com',
  production: 'https://id-eu.piano.io',
};

const loadPianoScript = async ({
  brand,
  currentBrand,
  didomiInitialized,
  setIsPianoLoaded,
  setIsSWGLoaded,
  externalCheckoutComplete,
  loginSuccess,
  registrationSuccess,
}: any) => {
  const { aid, hasSWG } = brand as BrandType;

  const isSandbox = process.env.REACT_APP_PIANO_SandBox === 'true';
  const defaultPianoIdUri = isSandbox
    ? PIANO_URIS.sandbox
    : PIANO_URIS.production;

  if (typeof window !== 'undefined' && aid) {
    addScript({
      src: '//cdn-eu.piano.io/api/tinypass.min.js',
      id: 'piano-script',
      async: true,
      onLoad: async () => {
        let tp: any = null;
        tp = window.tp || [];
        tp.push(['setAid', aid]);
        tp.push(['setSandbox', isSandbox]);
        tp.push(['setUsePianoIdUserProvider', true]);
        if (isSandbox) {
          tp.push([
            'setPianoIdUrl',
            currentBrand?.pianoIdUrl?.sandbox || defaultPianoIdUri,
          ]);
          tp.push(['setDebug', false]);
        } else {
          tp.push(['setEndpoint', 'https://buy-eu.piano.io/api/v3']);
          tp.push([
            'setPianoIdUrl',
            currentBrand?.pianoIdUrl?.production || defaultPianoIdUri,
          ]);
          tp.push(['setDebug', false]);
        }

        const handleBackToHome = () => hasSWG && tp.experience.execute();
        window.addEventListener('showSwgButton', handleBackToHome);
        await pianoConsentInit(currentBrand);
        tp.push([
          'init',
          () => {
            if (
              currentBrand.didomi !== undefined &&
              currentBrand.pianoConsent &&
              didomiInitialized
            ) {
              window.didomiOnReady = window.didomiOnReady || [];
              window.didomiOnReady?.push((Didomi: any) => {
                setPianoConsent({ Didomi, tp });
                Didomi.on('consent.changed', () => {
                  setPianoConsent({ Didomi, tp });
                });
              });
            }
            if (hasSWG) {
              tp.experience.init();
              setIsSWGLoaded(true);
            }
          },
        ]);

        tp.push([
          'addHandler',
          'registrationSuccess',
          (event: any) => {
            registrationSuccess(tp, event);
          },
        ]);

        tp.push([
          'addHandler',
          'loginSuccess',
          (event: any) => {
            loginSuccess(tp, event);
          },
        ]);

        if (hasSWG) {
          tp.push([
            'addHandler',
            'externalCheckoutComplete',
            (event: any) => {
              externalCheckoutComplete(event);
            },
          ]);
        }
        setIsPianoLoaded(true);
      },
    });
  }
};

export default loadPianoScript;
