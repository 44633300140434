import React, { useState } from 'react';
import { DialogContent } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Guid } from 'guid-typescript';
import { GeneralState } from '../../reducers/entities/general';
import { CreateOrderType, UpdateOrderType } from '../../actions/views/pay';
import AuthPiano from '../../components/AuthPiano/AuthPiano';
// import { UserTypes } from '../../actions/types';
import browserHistory from '../../utils/browser_history';
import {
  BILLING_ADDRESS_PAGE,
  CONTACT_DATA_PAGE,
  DELIVERY_ADDRESS_PAGE,
  SHORT_FORM_PAGE,
} from '../../utils/routes';
import { UserState } from '../../reducers/entities/user';
import { AddUserType } from '../../actions/userActions';
import './style.css';
import { UserTypes } from '../../actions/types';

interface ILoginProps {
  general: GeneralState;
  user: UserState;
  actions: {
    addUser: AddUserType;
    createOrder: CreateOrderType;
    updateOrder: UpdateOrderType;
  };
}

const Login = ({ general, actions }: ILoginProps) => {
  const dispatch = useDispatch();
  const { productID, promocode, gift, intent, editionID, pianoInitialized } =
    general.appState;
  const { createOrder, addUser, updateOrder } = actions;

  const [logginToken, setLogginToken] = useState<string>('');
  const [creatingOrder, setCreatingOrder] = useState<boolean>(false);

  const redirectToForm = (id: string | undefined) => {
    if (logginToken === '' && id === undefined) {
      return;
    }

    const { gifts, paymentMethodsDto, channelDto } =
      general.appState.currentProduct || {};

    const hasGift = (gifts || '').length > 0;
    const includePaper = channelDto?.includePaper;
    const isDomiciliation = (paymentMethodsDto || []).some(
      (e: any) => e.name === 'Domiciliation'
    );
    if (includePaper) {
      // Includes a gift or some physical paper
      browserHistory.replace(CONTACT_DATA_PAGE);
    } else if (hasGift) {
      browserHistory.replace(DELIVERY_ADDRESS_PAGE);
    } else if (isDomiciliation) {
      browserHistory.replace(BILLING_ADDRESS_PAGE);
    } else {
      browserHistory.replace(SHORT_FORM_PAGE);
    }
  };

  const managingOrder = async () => {
    if (creatingOrder) {
      return;
    }
    setCreatingOrder(true);
    if (intent) {
      await updateOrder({
        id: Guid.parse(intent).toString(),
        resetThirdParty: true,
        productId: productID,
        promoCode: promocode,
        gift,
        ...(editionID && { editionId: editionID }),
      });

      await redirectToForm(intent);
    } else if (productID) {
      const id = await createOrder({
        productId: productID,
        resetThirdParty: true,
        promoCode: promocode,
        gift,
        ...(editionID && { editionId: editionID }),
      });
      if (id) {
        await redirectToForm(id);
      }
    }
  };

  const managingUser = async (e: any) => {
    await addUser(
      e.user.email,
      e.user.firstName,
      e.user.lastName,
      e.user.valid
    );
  };

  const handleLogin = async (e: any) => {
    if (logginToken !== e.token) {
      setLogginToken(e.token);
      await dispatch({
        type: UserTypes.LOGIN_SUCCESS,
        data: e,
      });
      localStorage.setItem('token', e.token);
      await managingUser(e);
      await managingOrder();
    }
  };

  return (
    <DialogContent className="auth_content" style={{ minWidth: '400px' }}>
      {pianoInitialized && (
        <AuthPiano
          handleloggedIn={handleLogin}
          authType="login"
          asModal={false}
        />
      )}
    </DialogContent>
  );
};

export default Login;
