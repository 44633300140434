import { bindActionCreators, AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Duration from './Duration';
import {
  selectDuration,
  getDurations,
  selectProduct,
  SetCart,
} from '../../actions/views/duration';
import { selectGift, getGifts } from '../../actions/views/gift';
import { RootState } from '../../reducers';
import { getProduct } from '../../actions/views/pay';

const mapStateToProps = (state: RootState) => {
  const { user, general } = state.entities;
  const { router } = state;
  return {
    user,
    general,
    currentPath: router.location,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  dispatch,
  actions: bindActionCreators(
    {
      selectDuration,
      selectProduct,
      getDurations,
      getGifts,
      selectGift,
      getProduct,
      SetCart,
    },
    dispatch
  ),
});

export type DurationProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Duration);
