import { useEffect } from 'react';
import { BrandType } from '../reducers/entities/general';

const useFontImport = (brand: BrandType) => {
  useEffect(() => {
    if (!brand) {
      return () => {};
    }

    let style: HTMLStyleElement | null = null;

    const fetchCSS = async () => {
      try {
        // Fetch the CSS file
        const response = await fetch(`/css/${brand.name}-font.css`);

        // Check if the response is successful
        if (!response.ok) {
          console.error('Failed to fetch brand font CSS');
          return;
        }

        // Get the CSS content as text
        const cssContent = await response.text();

        // Create a <style> tag
        style = document.createElement('style');

        // Set the innerHTML of the <style> tag with the CSS content
        style.innerHTML = cssContent;

        // Append the <style> tag to the <head> of the document
        document.head.appendChild(style);
      } catch (error) {
        console.error('Error fetching brand font CSS:', error);
      }
    };

    fetchCSS();

    return () => {
      if (style != null) {
        document.head.removeChild(style);
      }
    };
  }, [brand]);
};

export default useFontImport;
