import React, { useState } from 'react';
import { Menu, MenuItem, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { GeneralState } from '../../reducers/entities/general';
import { UserState } from '../../reducers/entities/user';
import Logout from '../Header/Components/Links';
import { getCurrentBrand } from '../../utils/brands';
import AccountIcon from '../AccountIcon/AccountIcon';
import { makeTextEllipsis } from '../../utils/helpers';

// import Logout from '../Header/Components/Links';

interface INavbarProps {
  general: GeneralState;
  user: UserState;
  openLogginModal: (e: React.MouseEvent) => void;
  logout: Function;
  isModalOpen: boolean;
  uri: string;
}

const useStyles = makeStyles({
  root: {
    paddingTop: '11px',
    paddingBottom: '11px',
  },
});

const NavbarLaLibre = ({
  general,
  user,
  openLogginModal,
  isModalOpen,
  uri,
}: INavbarProps) => {
  const { brand } = general.appState;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const [connected, setConnected] = useState<JSX.Element | null>(null);
  const [firstname, setFirstname] = useState<JSX.Element | null>(null);
  const [email, setEmail] = useState<JSX.Element | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  function setPopupContent() {
    if (user.currentUser.isAuth) {
      setConnected(
        <MenuItem
          href={uri}
          target="_blank"
          onClick={handleClose}
          component="a"
          style={{ color: brand.thirdColor }}
          className={`MI-Connected ${brand.rootClassName}`}
        >
          Connecté
        </MenuItem>
      );
    }

    if (user.currentUser.firstname) {
      setFirstname(
        <MenuItem
          href={uri}
          target="_blank"
          onClick={handleClose}
          component="a"
          className="MI-Firstname"
        >
          {makeTextEllipsis(user.currentUser.firstname, 50)}
        </MenuItem>
      );
    }

    if (user.currentUser.email) {
      setEmail(
        <MenuItem
          href={uri}
          target="_blank"
          onClick={handleClose}
          component="a"
          className="MI-Email"
          classes={{ root: classes.root }}
        >
          {user.currentUser.email}
        </MenuItem>
      );
    }
  }

  const handleMenuOpen = (e: React.MouseEvent) => {
    setPopupContent();
    setAnchorEl(e.currentTarget);
  };

  const handleBackButton = () => {
    window.open(
      getCurrentBrand(brand.name).uri,
      '_self',
      'noopener,noreferrer'
    );
  };

  // @ts-ignore
  return (
    <div className={`navBar ${brand.rootClassName}`}>
      <button
        className="nav-backButton"
        onClick={handleBackButton}
        onKeyUp={handleBackButton}
        type="button"
      >
        <span className="hidden lg:block leading-5">
          Retour
          <br />
          au site
        </span>
      </button>
      <div
        className={`ap-PrimaryMenu-item content${brand.logoWrapperClassName}`}
      >
        <Link
          to="/"
          onClick={() => {
            localStorage.removeItem('campaign');
          }}
        >
          <img
            className="Brandinglogo"
            alt="logo"
            src={`${process.env.REACT_APP_CDN_URL}/${brand.name}.svg`}
          />
        </Link>
      </div>

      {user.currentUser.isAuth ? (
        <div className="account-wrapper">
          <AccountIcon
            className="account"
            onClick={handleMenuOpen}
            aria-owns={anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true"
          />
          <Menu
            id={`account-menu ${brand.rootClassName}`}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {firstname}
            {connected}
            {email}
            <Logout onClick={handleClose} />
          </Menu>
        </div>
      ) : (
        <div className="account-wrapper">
          <AccountIcon
            className={`${
              isModalOpen ? 'account-modal-open ' : ''
            }account disconnected`}
            onClick={openLogginModal}
            ariaLabel="se connecter"
          />
        </div>
      )}
    </div>
  );
};

export default NavbarLaLibre;
