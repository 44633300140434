const FAQ_PAGE = 'page';
const FAQ_PUSH = 'push';
const FAQ_STANDALONE = 'standalone';
const FAQ_INPUT = 'input';
const FAQ_SELECT = 'select';
const FAQ_CONTEXT = 'context';

const SMARTFAQ_TYPES = {
  FAQ_PAGE,
  FAQ_PUSH,
  FAQ_STANDALONE,
  FAQ_INPUT,
  FAQ_SELECT,
  FAQ_CONTEXT,
};

export default SMARTFAQ_TYPES;
