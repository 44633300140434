import React from 'react';
import { ReactComponent as Visa } from '../../images/payment/visa.svg';
import { ReactComponent as Mastercard } from '../../images/payment/mastercard-2.svg';
import { ReactComponent as Bancontact } from '../../images/payment/Bancontact_logo.svg';
import { ReactComponent as Paypal } from '../../images/payment/PayPal.svg';
import { ReactComponent as Sepa } from '../../images/payment/Combined Shape.svg';
import { ReactComponent as AMEX } from '../../images/payment/amex.svg';
import { ReactComponent as BankTransfert } from '../../images/payment/virement.svg';
import SecureSpace from '../SecureSpace';

import './PaymentMethodFooter.css';

interface IPaymentMethodFooter {
  brandName: string;
}

const PaymentMethodFooter = ({ brandName }: IPaymentMethodFooter) => {
  const isEdA = () => brandName === 'lavenir';

  return (
    <section className="paymentMethod">
      <strong>Modes de paiement acceptés</strong>
      <div className="paymentMethod-list">
        <Visa />
        <Mastercard />
        {!isEdA() && <AMEX />}
        <Paypal />
        <Bancontact />
        <Sepa />
        {isEdA() && <BankTransfert />}
      </div>
      <SecureSpace />
    </section>
  );
};

export default PaymentMethodFooter;
