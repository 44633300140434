import React from 'react';
import './style.css';
import CheckIcon from '../CheckIcon/CheckIcon';

interface ITextSliderProps {
  items: string[];
}

/**
 * Ok, this is not from me. 🫣 Source: https://codepen.io/mscharl/pen/MjVRzB
 * I just take the source and adapt it to the Aboshop.
 */
const TextSlider = ({ items }: ITextSliderProps) => (
  <header className="ms-header">
    <h1 className="ms-header__title">
      <div className="ms-slider">
        <ul className="ms-slider__words">
          {items.map((item: string) => (
            <li
              className="ms-slider__word"
              key={`microheader-${item.replaceAll(' ', '-')}`}
            >
              <CheckIcon className="ms-slider__icon" />
              &nbsp;{item}
            </li>
          ))}
          <li className="ms-slider__word">
            <CheckIcon className="ms-slider__icon" />
            &nbsp;{items[0]}
          </li>
        </ul>
      </div>
    </h1>
  </header>
);

export default TextSlider;
