/**
 * @description Add script to body
 * @param {object} script
 */
const addScript = ({ src, id, onLoad, async, attribute }: any) => {
  const existing = document.getElementById(id);
  if (existing) {
    return existing;
  }
  const script = document.createElement('script');
  script.src = src;
  script.id = id;
  script.async = async;
  if (attribute) {
    attribute.forEach((value: any, key: any) => {
      script.setAttribute(key, value);
    });
  }
  script.onerror = () => {
    window.dispatchEvent(new CustomEvent('unable_to_get_script'));
  };
  script.onload = () => {
    if (onLoad) {
      onLoad();
    }
  };
  document.body.appendChild(script);
  return script;
};

export default addScript;
