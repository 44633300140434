import React from 'react';
import { ReactComponent as Crown } from '../../../images/icons/crown.svg';

interface ITrophyIconProps {
  className?: string;
}

const TrophyIcon = ({ className }: ITrophyIconProps) => (
  <Crown className={className} style={{ fill: 'var(--primary)' }} />
);

export default TrophyIcon;
