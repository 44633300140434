import React from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import browserHistory from '../utils/browser_history';
import root from '../components/root';
import ScrollTop from '../components/ScrollTop';
import ErrorBoundary from '../components/ErrorBoundary';

const THEME = createTheme({
  typography: {
    fontFamily: "'FontMedium', Georgia, 'Times New Roman', Times, serif;",
    fontSize: 12.25,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  spacing: 16,
});

function Root({ store }: any) {
  return (
    <MuiThemeProvider theme={THEME}>
      <Provider store={store}>
        <ConnectedRouter history={browserHistory}>
          <ScrollTop>
            <ErrorBoundary>
              <Route path="/" component={root} />
            </ErrorBoundary>
          </ScrollTop>
        </ConnectedRouter>
      </Provider>
    </MuiThemeProvider>
  );
}

export default Root;
