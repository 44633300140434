export const HOME_PAGE = '/';
export const VOUCHER_PAGE = '/voucher';
export const PROMOTION_PAGE = '/promotion';
export const GOTO_PAGE = '/goto';
export const ABORENEWAL_PAGE = '/aborenewal';
export const DURATION_PAGE = '/duration';
export const GIFT_PAGE = '/gift';
export const EDITION_PAGE = '/edition';
export const PAY_PAGE = '/pay';
export const PAY_RENEWAL_PAGE = '/pay-renewal';
export const DELIVERY_PAGE = '/delivery';
export const FINISHED_PAGE = '/finished';
export const USER_LOGIN_PAGE = '/user/login';
export const CONTACT_DATA_PAGE = '/contact-data';
export const DELIVERY_ADDRESS_PAGE = '/contact-data/delivery';
export const BILLING_ADDRESS_PAGE = '/contact-data/billing';
export const SHORT_FORM_PAGE = '/contact-data/short';
export const TEMPORARY_OFFERS_PAGE = '/temporary-offers';
export const COOKIE_PAGE = '/cookie/:hash';
export const ERROR_PAGE = '/error';
export const FREE_CAMPAIGN_PAGE = '/page/:freeCampaignName';
