import { isValidPhoneNumber } from 'react-phone-number-input';

export const validatePhoneNumber = (value: string): boolean | string => {
  const error: string = 'Numéro de téléphone invalide';
  const phoneNumber: string = value || '';

  if (phoneNumber.slice(0, 3) === '+32') {
    return isValidPhoneNumber(phoneNumber) || error;
  }

  return phoneNumber.slice(4, phoneNumber.length).length === 0 ? error : true;
};

export default { validatePhoneNumber };
