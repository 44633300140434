import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FormControlLabel } from '@material-ui/core';
import { UserState } from '../../reducers/entities/user';
import { GeneralState } from '../../reducers/entities/general';
import ContactDataShort from '../../components/Forms/ContactDataShort';
import { addAddressInfo } from '../../actions/views/delivery';
import LoadingScreen from '../../components/LoadingScreen';
import Message from '../../components/Message';
import browserHistory from '../../utils/browser_history';
import { PAY_PAGE, PAY_RENEWAL_PAGE } from '../../utils/routes';
import { getUrlParamsFromLs } from '../../utils/url';
import { defaultValues } from '../Delivery/Delivery';
import CustomCheckbox from '../../components/Checkbox';
import Button from '../../components/Button';
import { UserUpdateType } from '../../actions/userActions';
import BillingForm, {
  BillingFormValues,
} from '../../components/BillingForm/BillingForm';
import handleKeyboardEvent from '../../utils/handleKeyboardEvent';

interface IShortFormProps {
  user: UserState;
  general: GeneralState;
}

type ShortFormValues = {
  short: {
    phone: string;
  };
  billing: BillingFormValues;
};

const ShortForm = ({ general }: IShortFormProps) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [withBilling, setWithBilling] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { register, handleSubmit, errors, control, setValue } =
    useForm<ShortFormValues>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues: {
        short: {
          phone: '',
        },
        billing: { ...defaultValues },
      },
    });

  const toggleDifferentAddress = () => {
    setWithBilling(!withBilling);

    if (!withBilling) {
      setValue('billing', {});
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    try {
      let subForm: UserUpdateType = {
        delivery: false,
      };
      if (withBilling && data.billing) {
        subForm = {
          firstname: data.billing.firstName,
          lastname: data.billing.lastName,
          delivery: false,
          address: {
            street: data.billing.address.street,
            houseNumber: parseInt(data.billing.address.houseNumber, 10),
            postalBox: data.billing.address.postalBox,
            postalCode: parseInt(data.billing.address.postalCode, 10),
            city: data.billing.address.city,
            country: data.billing.address.country,
          },
        };
        await dispatch(
          await addAddressInfo(
            'billing',
            {
              ...subForm,
              CompanyName: data.billing.companyName,
              VATNumber: data.billing.vatNumber,
            },
            String(general.appState.intent)
          )
        );
      }
      await dispatch(
        await addAddressInfo(
          'personal',
          {
            ...subForm,
            phoneNumber: data.short.phone,
          },
          String(general.appState.intent)
        )
      );
      if (!general.appState.renewal) {
        browserHistory.push(PAY_PAGE + getUrlParamsFromLs());
      } else {
        browserHistory.push(PAY_RENEWAL_PAGE + getUrlParamsFromLs());
      }
    } catch (err: any) {
      setError(`${err.message}`);
    } finally {
      setIsLoading(false);
    }
  });

  if (isLoading) {
    return (
      <LoadingScreen
        customColor={general.appState.brand.thirdColor}
        size={60}
      />
    );
  }

  return (
    <div className="form_overlay">
      {error && (
        <Message className="error_message" intent="DANGER" message={error} />
      )}

      <form onSubmit={onSubmit} className="flex flex-col space-y-2">
        <ContactDataShort
          register={register}
          control={control}
          errors={errors}
        />

        <div className="TextField_Container">
          <FormControlLabel
            className="mx-auto"
            control={
              <CustomCheckbox
                color={general.appState.brand.thirdColor}
                checked={withBilling}
                value="conditions"
                onKeyDown={(e: KeyboardEvent) =>
                  handleKeyboardEvent(e, toggleDifferentAddress)
                }
                onChange={toggleDifferentAddress}
              />
            }
            label="Je souhaite une facture "
          />
        </div>

        {withBilling && (
          <>
            <h2>Adresse de facturation</h2>
            <BillingForm
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              formIn="billing"
              askBilling={false}
              askPhoneNumber={false}
            />
          </>
        )}

        <Button
          customColor={general.appState.brand.thirdColor}
          className="button validate"
          color="primary"
          type="submit"
          fullWidth
          size="large"
        >
          Confirmer
        </Button>
        <p className="mt-4">* champ obligatoire</p>
      </form>
    </div>
  );
};

export default ShortForm;
