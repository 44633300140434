/**
 * @description Add external scripts to body
 * @param {object} script
 */
const addExternalScript = (script: any) => {
  if (typeof window !== 'undefined') {
    const s = document.createElement('script');
    if (script.src) {
      s.src = script.src;
      s.type = 'text/javascript';
      document.head.appendChild(s);
    } else {
      s.type = script.type || 'text/javascript';
      if (script.id) {
        s.id = script.id;
      }
      s.async = true;
      s.text = script.text;
      if (document.body != null) {
        document.body.appendChild(s);
      }
    }
  }
  return null;
};

export default addExternalScript;
