import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { addUser, logout } from '../../actions/userActions';
import { RootState } from '../../reducers';
import LoggedIn from './LoggedIn';

const mapStateToProps = (state: RootState) => {
  const { user, general } = state.entities;
  return {
    user,
    general,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  dispatch,
  actions: bindActionCreators(
    {
      addUser,
      logout,
    },
    dispatch
  ),
});

export type LoggedInProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(LoggedIn);
