import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { LoggedInProps } from '.';
import AuthPiano from '../AuthPiano';
import { UserTypes } from '../../actions/types';

interface LoggedInState {
  logginToken: string;
  isUserAdded: boolean;
}

export default class LoggedIn extends React.PureComponent<
  LoggedInProps,
  LoggedInState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      logginToken: '',
      isUserAdded: false,
    };
    this.isValidState = this.isValidState.bind(this);
  }

  componentDidMount() {
    if (!this.props.user.currentUser.isAuth) {
      this.props.actions.logout();
    } else {
      // TODO: check if email is verified, else redirect home with modal open
    }
  }

  handleloggedIn = async (e: any) => {
    const { logginToken } = this.state;
    if (logginToken !== e.token) {
      this.setState({ logginToken: e.token });
      await this.props.dispatch({
        type: UserTypes.LOGIN_SUCCESS,
        data: e,
      });
      await this.props.actions.addUser(
        e.user.email,
        e.user.firstName,
        e.user.lastName,
        e.user.valid
      );
      this.setState({ isUserAdded: true });
    }
  };

  isValidState() {
    return this.props.user.currentUser.isAuth;
  }

  render() {
    if (!this.isValidState()) {
      return (
        <Dialog
          open
          id="modal"
          className={`auth_modal ${this.props.general.appState.brand.rootClassName}`}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent className="auth_content" style={{ minWidth: '400px' }}>
            <AuthPiano
              handleloggedIn={async (val: any) => this.handleloggedIn(val)}
              authType="login"
            />
          </DialogContent>
        </Dialog>
      );
    }
    return this.props.children;
  }
}
