import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GeneralTypes } from '../types';
import { log, getAPIUrl } from '../generalActions';
import { AppAction } from '../../reducers/entities/general';
import { flyingFishEvents, trackEvent } from '../../utils/FlyingFish';

export type SelectEditionType = (
  editionId: number,
  editionName?: string,
  track?: boolean
) => void;
export const selectEdition: SelectEditionType =
  (editionId, editionName = 'From Goto', track = true) =>
  (dispatch: any, getState: any) => {
    localStorage.setItem('editionID', editionId.toString());
    dispatch({ type: GeneralTypes.SELECT_EDITION, data: editionId });
    const { appActions } = getState().entities.general;
    const { uuid } = getState().entities.user.currentUser;
    if (appActions !== null && uuid !== '')
      dispatch(
        log({
          actionDto: appActions.find(
            (a: AppAction) => a.definition === 'SelectEdition'
          ),
          uuid,
          result: editionName,
        })
      );
    if (track) {
      trackEvent(flyingFishEvents.edition_select(editionName));
    }
  };

// Fetch editions call
export type GetEditionsType = () => any;
export const getEditions: GetEditionsType =
  () => async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) => {
    try {
      const resp = await fetch(
        `${getAPIUrl()}/Product/${
          getState().entities.general.appState.productID
        }`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      throw error;
    }
  };

export type ResetEditionStateType = () => void;
export const resetEditionState: ResetEditionStateType = () => () => {
  localStorage.removeItem('intent');
  localStorage.removeItem('editionID');
};
