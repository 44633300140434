import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { UserState } from '../../reducers/entities/user';
import { GeneralState } from '../../reducers/entities/general';
import { defaultValues } from '../Delivery/Delivery';
import Message from '../../components/Message';
import BillingForm, {
  BillingFormValues,
} from '../../components/BillingForm/BillingForm';
import Button from '../../components/Button';
import { addAddressInfo } from '../../actions/views/delivery';
import { UserUpdateType } from '../../actions/userActions';
import browserHistory from '../../utils/browser_history';
import { PAY_PAGE, PAY_RENEWAL_PAGE } from '../../utils/routes';
import { getUrlParamsFromLs } from '../../utils/url';
import LoadingScreen from '../../components/LoadingScreen';
import useToggle from '../../hooks/useToggle';

interface IBillingProps {
  user: UserState;
  general: GeneralState;
}

type FormValues = {
  billing: BillingFormValues;
};

const Billing = ({ general }: IBillingProps) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const isVoucher = (general.appState.voucher || -1) > 0;
  const [sendBill, toggleBill] = useToggle(false);

  const { register, handleSubmit, errors, control, setValue } =
    useForm<FormValues>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues: {
        billing: { ...defaultValues },
      },
    });

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    try {
      const subForm: UserUpdateType = {
        firstname: data.billing.firstName,
        lastname: data.billing.lastName,
        phoneNumber: data.billing.phoneNumber,
        delivery: false,
        address: {
          street: data.billing.address.street,
          houseNumber: parseInt(data.billing.address.houseNumber, 10),
          postalBox: data.billing.address.postalBox,
          postalCode: parseInt(data.billing.address.postalCode, 10),
          city: data.billing.address.city,
          country: data.billing.address.country,
        },
      };
      await dispatch(
        await addAddressInfo(
          'personal',
          subForm,
          String(general.appState.intent)
        )
      );
      if (sendBill) {
        subForm.VATNumber = data.billing.vatNumber;
        subForm.CompanyName = data.billing.companyName;
        await dispatch(
          await addAddressInfo(
            'billing',
            subForm,
            String(general.appState.intent)
          )
        );
      }
      if (!general.appState.renewal) {
        browserHistory.push(PAY_PAGE + getUrlParamsFromLs());
      } else {
        browserHistory.push(PAY_RENEWAL_PAGE + getUrlParamsFromLs());
      }
    } catch (err: any) {
      setError(`${err.message}`);
    } finally {
      setIsLoading(false);
    }
  });

  if (isLoading) {
    return (
      <LoadingScreen customColor={general.appState.brand.color} size={60} />
    );
  }

  return (
    <div className="step_formule_wrapper w-full">
      <div className="form_overlay">
        {error && (
          <Message className="error_message" intent="DANGER" message={error} />
        )}

        <form onSubmit={onSubmit} className="flex flex-col space-y-2">
          <h2>Adresse de facturation</h2>
          <BillingForm
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            formIn="billing"
            includeBilling={!isVoucher}
            toggleBill={toggleBill}
          />

          <Button
            customColor={general.appState.brand.thirdColor}
            className="button validate"
            color="primary"
            type="submit"
            fullWidth
            size="large"
          >
            Confirmer
          </Button>
          <p className="mt-4">* champ obligatoire</p>
        </form>
      </div>
    </div>
  );
};

export default Billing;
