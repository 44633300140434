import { bindActionCreators, AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Edition from './Edition';
import {
  getEditions,
  selectEdition,
  resetEditionState,
} from '../../actions/views/edition';
import { deleteIntent, createOrder } from '../../actions/views/pay';
import { log } from '../../actions/generalActions';
import { RootState } from '../../reducers';
import { selectOffer } from '../../actions/views/home';

const mapStateToProps = (state: RootState) => {
  const { user, general } = state.entities;
  return {
    user,
    general,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      selectEdition,
      getEditions,
      resetEditionState,
      createOrder,
      deleteIntent,
      log,
      selectOffer,
    },
    dispatch
  );

export type EditionProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Edition);
