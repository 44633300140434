import React, { useState, useEffect, ChangeEvent } from 'react';
import { useDebounce } from '../../utils/helpers';

import '../Address/style.css';

interface IAutoCompleteProps {
  getSuggestions: Function;
  placeholder?: string;
  name: string;
  label: string;
  disabled?: boolean;
  setValue: (data: any) => void;
  filterValue?: (data: any, value: string) => boolean;
  displayResults: (data: any) => string;
  className?: string;
  currentValue?: string;
  readonly?: boolean;
  forceClickSuggestion?: boolean;
}

function AutoComplete(props: IAutoCompleteProps) {
  let suggestionSelected: boolean = false;
  const [searchVal, setSearchVal] = useState<string>(props.currentValue || '');
  const [suggestions, setSuggestions] = useState([]);
  const [touched, setTouched] = useState<boolean>(false);

  const debouncedSearchTerm = useDebounce(searchVal, 500);

  useEffect(() => {
    async function search(val: string) {
      if (val.length >= 3) {
        const results = await props.getSuggestions(val);
        setSuggestions(results);
      }
    }

    if (touched && debouncedSearchTerm !== '') {
      search(searchVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <div
      className={`field autocomplete Autocomplete_Container relative ${props.className}`}
    >
      <label htmlFor={props.label} className="label">
        {props.label}
      </label>
      <input
        name={props.name}
        id={props.label}
        autoComplete="off"
        className="input"
        disabled={props.disabled}
        aria-placeholder={props.placeholder}
        placeholder={props.placeholder}
        type="text"
        value={searchVal}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.value !== searchVal) {
            suggestionSelected = false;
            setTouched(true);
            setSearchVal(e.target.value);
          }
        }}
        onBlur={() => {
          setTimeout(() => {
            if (touched && !suggestionSelected && props.forceClickSuggestion) {
              if (props.filterValue && suggestions.length > 0) {
                const filteredSuggestions = suggestions.filter((s) =>
                  props.filterValue!(s, searchVal)
                );

                if (filteredSuggestions.length === 1) {
                  const [sugg] = filteredSuggestions;
                  setSearchVal(props.displayResults(sugg));
                  props.setValue(sugg);
                  setSuggestions([]);
                  setTouched(false);
                  return;
                }
              }

              setSearchVal('');
              props.setValue('');
              setSuggestions([]);
              setTouched(false);
            }
          }, 100);
        }}
        readOnly={!!props.readonly}
        aria-readonly={!!props.readonly}
      />
      {suggestions.length > 0 && (
        <div className="autocomplete-box">
          {suggestions.map((suggestion: any, index: number) => (
            <button
              type="button"
              key={`${props.label}-${props.displayResults(suggestions[index])}`}
              onClick={() => {
                const currentSuggestion = suggestions[index];
                setSearchVal(props.displayResults(currentSuggestion));
                props.setValue(currentSuggestion);
                setSuggestions([]);
                setTouched(false);
                suggestionSelected = true;
              }}
            >
              {props.displayResults(suggestion)}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default AutoComplete;
