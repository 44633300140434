import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import fetch from 'isomorphic-fetch';
import { GeneralTypes } from './types';
import { AppAction } from '../reducers/entities/general';
import { logout } from './userActions';

export type GetAPIUrlType = () => any;

type Log = {
  uuid: string;
  actionDto: AppAction | undefined;
  result: string; // Success error?
};

export type FetchWithCredentialsType = (url: string, options: any) => any;
export type GetAppActionsType = () => any;
export const getAPIUrl: GetAPIUrlType = () => {
  if (process.env.REACT_APP_URL === 'http://localhost:3000') {
    return process.env.REACT_APP_API_URL;
  }
  return `${window.location.origin}/API`;
};

export type LogType = (log: Log) => any;

export const fetchWithCredentials: FetchWithCredentialsType =
  (url, options) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) => {
    const { token } = getState().entities.user.currentUser;

    const builtOptions = {
      ...options,
      headers: {
        ...(options.headers || {}),
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    };

    const response = await fetch(url, builtOptions);

    if (response.status === 401) {
      if (response.headers.has('Token-Invalid')) {
        if (getState().entities.user.currentUser.isAuth) {
          await dispatch(logout());
        }
      }
      if (response.headers.has('Token-Expired')) {
        // await dispatch(refreshToken(true));
        // return await dispatch(fetchWithCredentials(url, options));
        await dispatch(logout());
      }
    } else if (response.ok) {
      return response;
    }

    return response;
  };

export const log: LogType =
  (logg) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      await dispatch(
        fetchWithCredentials(`${getAPIUrl()}/History/AddHistory`, {
          method: 'POST',
          body: JSON.stringify(logg),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
      );
      dispatch({ type: GeneralTypes.LOG_ACTION });
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      // Never break app flow if this call does not work
      return true;
    }
  };

export const getAppActions: GetAppActionsType =
  () => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const resp = await fetch(`${getAPIUrl()}/History/Actions`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    const data = await resp.json();
    if (!resp.ok) throw new Error(data.message);
    dispatch({ type: GeneralTypes.GET_APP_ACTIONS, data });
    return data;
  };
