import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { useDispatch } from 'react-redux';
import { FinishedProps } from '.';
import './Finished.css';
import CheckIcon from '../CheckIcon/CheckIcon';
import { getCurrentBrand } from '../../utils/brands';
import {
  flyingFishEvents,
  getEdition as getEditionFromEditions,
  IDataLayerCommerceItem,
  trackEvent,
} from '../../utils/FlyingFish';
import { GeneralTypes } from '../../actions/types';
import { getFlowtype, getOffer } from '../../hooks/usePageViews';

const Finished = (props: FinishedProps) => {
  const dispatch = useDispatch();
  const {
    currentProduct,
    editionID,
    brand,
    transaction,
    promocode,
    renewal,
    swgBuy,
  } = props.general.appState;
  const { title, content } = getCurrentBrand(brand.name).confirmation || {};

  useEffect(() => {
    if (currentProduct && transaction) {
      trackEvent(
        flyingFishEvents.payment_validate(currentProduct.name, transaction)
      );
      trackEvent(
        flyingFishEvents.purchase(transaction, {
          item_name: getOffer(currentProduct),
          item_category: getFlowtype(currentProduct, window.location),
          item_category1: currentProduct.duration.toString(10),
          ...(editionID
            ? {
                item_category2: getEditionFromEditions(
                  currentProduct,
                  editionID
                ),
              }
            : {}),
          coupon: promocode,
          quantity: '1',
          price: currentProduct.price.toString(10),
        } as IDataLayerCommerceItem)
      );
    }
    const cleanup = () => {
      dispatch({
        type: GeneralTypes.RESET_AFTER_PAYMENT_SUCCESS,
      });
    };

    window.addEventListener('beforeunload', cleanup);

    return () => {
      window.removeEventListener('beforeunload', cleanup);
      cleanup();
    };
  }, [currentProduct, dispatch, editionID, promocode, transaction]);

  const isSwg = currentProduct === null && swgBuy;
  const hasProduct = currentProduct !== null;
  if (!(hasProduct || isSwg || renewal)) {
    return <Redirect to="/" />;
  }

  return (
    <div className="step_formule_wrapper">
      <div className="bg-green-600 px-4 py-4 rounded flex flex-row space-x-4 items-center mb-4">
        <CheckIcon className="w-8" primary />
        <p className="font-medium text-white">
          Accédez dès à présent à tous les articles de la rédaction.
        </p>
      </div>

      {title && content ? (
        <>
          <h2 className="text-base font-medium leading-6">{title}</h2>
          <p>{content}</p>
        </>
      ) : null}

      <a
        href={brand.finishedLink}
        className="px-4 py-2 bg-ipm-yellow-l text-ipm-yellow-d font-black rounded my-4 text-center"
      >
        {brand.finishedText}
      </a>
    </div>
  );
};

export default Finished;
