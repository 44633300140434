import { useLocation } from 'react-router';
import { useEffect } from 'react';
import {
  ABORENEWAL_PAGE,
  BILLING_ADDRESS_PAGE,
  CONTACT_DATA_PAGE,
  DELIVERY_ADDRESS_PAGE,
  DURATION_PAGE,
  EDITION_PAGE,
  FINISHED_PAGE,
  GIFT_PAGE,
  HOME_PAGE,
  PAY_PAGE,
  PAY_RENEWAL_PAGE,
  SHORT_FORM_PAGE,
  TEMPORARY_OFFERS_PAGE,
  USER_LOGIN_PAGE,
  VOUCHER_PAGE,
} from '../utils/routes';
import {
  FlyingFishBasePage,
  FlyingFishLevel,
  getEdition,
  IDataLayerItem,
} from '../utils/FlyingFish';
import { AppState, ProductType } from '../reducers/entities/general';
import { UserState } from '../reducers/entities/user';

export const getOffer = (product: ProductType | null) =>
  product?.channelDto?.name || 'UNKNOWN';

export const getFlowtype = (product: ProductType | null, location: any) => {
  if (product?.typeDto?.name) return product?.typeDto?.name;
  return !location.search.includes('campaign') ? 'Standard' : 'Promo';
};

const usePageViews = (
  {
    currentProduct,
    editionID,
    deliveryOption,
    paymentType,
    transaction,
    offerName,
    gift,
    swgBuy,
    pianoInitialized,
  }: AppState,
  user: UserState
) => {
  const location = useLocation();
  const getCallbackByLevels = (url: string) => {
    const levels = {
      [TEMPORARY_OFFERS_PAGE]: FlyingFishLevel.PAGE,
      [HOME_PAGE]: FlyingFishLevel.PAGE,
      [VOUCHER_PAGE]: FlyingFishLevel.VOUCHERPAGE,
      [ABORENEWAL_PAGE]: FlyingFishLevel.RENEWALPAGE,
      [DURATION_PAGE]: FlyingFishLevel.DURATION,
      [GIFT_PAGE]: FlyingFishLevel.GIFT,
      [EDITION_PAGE]: FlyingFishLevel.EDITION,
      [USER_LOGIN_PAGE]: FlyingFishLevel.FORM,
      [SHORT_FORM_PAGE]: FlyingFishLevel.FORM,
      [CONTACT_DATA_PAGE]: FlyingFishLevel.FULL_FORM,
      [BILLING_ADDRESS_PAGE]: FlyingFishLevel.FULL_FORM,
      [DELIVERY_ADDRESS_PAGE]: FlyingFishLevel.FULL_FORM,
      [PAY_PAGE]: FlyingFishLevel.FULL_FORM,
      [PAY_RENEWAL_PAGE]: FlyingFishLevel.FULL_FORM,
      [FINISHED_PAGE]: FlyingFishLevel.FINISHED,
    };

    return levels[url as keyof typeof levels] || FlyingFishLevel.PAGE;
  };

  const actions: Record<string, Function> = {
    [FlyingFishLevel.PAGE]: (url: string, userId: string): IDataLayerItem => ({
      ...FlyingFishBasePage(url, userId),
      subscription: {
        flowtype: getFlowtype(currentProduct, location),
      },
    }),
    [FlyingFishLevel.VOUCHERPAGE]: (
      url: string,
      userId: string
    ): IDataLayerItem => ({
      ...FlyingFishBasePage(url, userId),
      subscription: {
        flowtype: 'Voucher',
      },
    }),
    [FlyingFishLevel.RENEWALPAGE]: (
      url: string,
      userId: string
    ): IDataLayerItem => ({
      ...FlyingFishBasePage(url, userId),
      subscription: {
        flowtype: 'Renewal',
      },
    }),
    [FlyingFishLevel.DURATION]: (
      url: string,
      userId: string
    ): IDataLayerItem => ({
      ...FlyingFishBasePage(url, userId),
      subscription: {
        flowtype: getFlowtype(currentProduct, location),
        offer: offerName != null ? offerName : getOffer(currentProduct),
        temporaryOffer: gift ?? undefined,
      },
    }),
    [FlyingFishLevel.GIFT]: (url: string, userId: string): IDataLayerItem => ({
      ...FlyingFishBasePage(url, userId),
      subscription: {
        flowtype: getFlowtype(currentProduct, location),
        offer: getOffer(currentProduct),
        temporaryOffer: currentProduct?.gifts ?? undefined,
        duration: currentProduct?.duration,
        price: currentProduct?.price || 0,
      },
    }),
    [FlyingFishLevel.EDITION]: (
      url: string,
      userId: string
    ): IDataLayerItem => ({
      ...FlyingFishBasePage(url, userId),
      subscription: {
        flowtype: getFlowtype(currentProduct, location),
        offer: getOffer(currentProduct),
        temporaryOffer: gift ?? undefined,
        duration: currentProduct?.duration,
        price: currentProduct?.price || 0,
      },
    }),
    [FlyingFishLevel.FORM]: (url: string, userId: string): IDataLayerItem => ({
      ...FlyingFishBasePage(url, userId),
      subscription: {
        flowtype: getFlowtype(currentProduct, location),
        offer: getOffer(currentProduct),
        temporaryOffer: gift ?? undefined,
        duration: currentProduct?.duration,
        price: currentProduct?.price || 0,
        ...(typeof editionID === 'number'
          ? { edition: getEdition(currentProduct, editionID) }
          : {}),
      },
    }),
    [FlyingFishLevel.FULL_FORM]: (
      url: string,
      userId: string
    ): IDataLayerItem => ({
      ...FlyingFishBasePage(url, userId),
      subscription: {
        flowtype: getFlowtype(currentProduct, location),
        offer: getOffer(currentProduct),
        temporaryOffer: gift ?? undefined,
        duration: currentProduct?.duration,
        price: currentProduct?.price || 0,
        ...(typeof editionID === 'number'
          ? { edition: getEdition(currentProduct, editionID) }
          : {}),
        ...(Object.keys(deliveryOption || {}).length > 0
          ? { deliveryType: deliveryOption?.name }
          : {}),
      },
    }),
    [FlyingFishLevel.FINISHED]: (
      url: string,
      userId: string,
      swgType: boolean
    ): IDataLayerItem => ({
      ...FlyingFishBasePage(url, userId),
      subscription: {
        flowtype: getFlowtype(currentProduct, location),
        offer: getOffer(currentProduct),
        swgType: swgType ? 'google' : '',
        temporaryOffer: gift ?? undefined,
        duration: currentProduct?.duration,
        price: currentProduct?.price || 0,
        ...(typeof editionID === 'number'
          ? { edition: getEdition(currentProduct, editionID) }
          : {}),
        ...(Object.keys(deliveryOption || {}).length > 0
          ? { deliveryType: deliveryOption?.name }
          : {}),
        ...((paymentType || '').length > 0 ? { paymentType } : {}),
        ...((transaction || '').length > 0
          ? { transactionId: transaction }
          : {}),
      },
    }),
  };

  useEffect(() => {
    const level = getCallbackByLevels(location.pathname);
    const userId = user.currentUser.uuid;
    const callback = actions[level];
    if (callback && pianoInitialized) {
      const push = callback(location.pathname, userId, swgBuy);
      if (
        push?.subscription?.offer === undefined ||
        push?.subscription?.offer !== 'UNKNOWN'
      ) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(push);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, pianoInitialized]);
};

export default usePageViews;
